import { Formik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import { UserContext } from "../../context-provider/UserProvider";
import { Loader, Form } from "semantic-ui-react";
import logoRed from "../../assets/images/logoRed.svg";
import IconButton from "../ui/button";

export const Login = () => {
  const { login } = useContext(UserContext);
  const getFieldError = (field, errors, touched) => {
    return touched[field] ? errors[field] : "";
  };

  return (
    <div className="login-screen">
      <div className="login-container">
        {/* <div className="login-screen-header">
          <div>Login</div>
        </div> */}

        <img src={logoRed} alt="" style={{ width: 250, alignSelf: "center" }} />

        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required("Email is required")
              .email("Enter valid email"),
            password: Yup.string().required("Password is required"),
          })}
          onSubmit={async (values) => {
            login(values);
          }}
        >
          {({
            isSubmitting,
            handleChange,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            values,
          }) => (
            <Form className="login-form" onSubmit={handleSubmit}>
              <Form.Input
                id="email"
                label="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                name="email"
                error={!!getFieldError("email", errors, touched)}
                // helperText={getFieldError("email", errors, touched)}
                type="email"
                style={{ marginBottom: 20, flex: 1 }}
              />
              <Form.Input
                id="password"
                label="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                name="password"
                error={!!getFieldError("password", errors, touched)}
                // helperText={getFieldError("password", errors, touched)}
                type="password"
              />

              {/* <div className="login-info">
              <div className="login-message">{" Login message here"}</div>
              <div className="login-error">{"Login error here"}</div>
            </div> */}
              {isSubmitting ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: 120,
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <div className="login-form-actions">
                  <IconButton title="Login" text="Login" type="submit" />
                </div>
              )}
            </Form>
          )}
        </Formik>
        <div className="login-info-text">
          {/* <Button
          onClick={() => {
            history.push("/forgot-password");
          }}
        >
          Forgot Password (unused)
        </Button> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
