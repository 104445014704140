import useFetch from "../../../hooks/use-fetch";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context-provider/UserProvider";
import { useParams } from "react-router";
import { Loader, Card, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";

const CustomerResponses = () => {
  const apiCustomers = useFetch();
  const apiCampaigns = useFetch();

  const [customer, setCustomer] = useState({});
  const [campaigns, setCampaigns] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const { customerId, campaignId } = useParams();

  const getCustomerDetails = async () => {
    const response = await apiCustomers.fetchData(
      `api/customers/customer_details/${customerId}`
    );
    setCustomer(response);
    const campaignResponse = await apiCampaigns.fetchData(
      `api/customers/customer_responses/${customerId}/${campaignId}`
    );
    setCampaigns(campaignResponse);
    setIsFetching(false);
  };

  useEffect(() => {
    getCustomerDetails();
  }, []);

  return (
    <>
      {!isFetching ? (
        <div style={{ width: "100%" }}>
          <Card style={{ padding: "20px" }} fluid>
            <div>
              <Link to={`/customers/${customer.guid}`}>
                <h2>{customer.firstName + " " + customer.lastName}</h2>
              </Link>
              <h3>
                {campaigns.campaignType +
                  " Campaign: " +
                  campaigns.campaignName}
              </h3>
            </div>

            {campaigns.customerResponses.map((responsesByDate) => {
              return (
                <div style={{ margin: "10px" }}>
                  <Divider />
                  <div> Responded on: {responsesByDate.dateResponded}</div>
                  {responsesByDate.responses.map((response) => {
                    return (
                      <div style={{ marginTop: "10px" }}>
                        <div>Question: {response.questionText}</div>
                        <div>Response: {response.responseText}</div>
                        {response.tasksCreated.map((taskName) => {
                          return <li>{taskName}</li>;
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Card>
        </div>
      ) : (
        <Loader active />
      )}
    </>
  );
};

export default CustomerResponses;
