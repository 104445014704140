import { Formik } from "formik";
import { useContext } from "react";
import { Form, Card } from "semantic-ui-react";
import { UserContext } from "../../../context-provider/UserProvider";
import useFetch from "../../../hooks/use-fetch";
import IconButton from "../../ui/button";
import { HOST } from "../../../environment";

import "../style.scss";
const ExportSubscriptionData = ({ subscriptionId, subscription }) => {
  const { user } = useContext(UserContext);

  const exportData = async (values) => {
    await fetch(`${HOST}/api/subscriptions/export_subscription_data`, {
      type: "API",
      body: JSON.stringify(values),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
    })
      .then((response) => response.text())
      .then((result) => downloadCsv(result, values))

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  function downloadCsv(csvString, values) {
    var blob = new Blob([csvString]);
    // if (window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveBlob(blob, "filename.csv");
    // } else {
    var a = window.document.createElement("a");
    console.log(subscription);

    //TODO: Backend returns a FileResult that should already have a file name, just dont know how to access it
    a.href = window.URL.createObjectURL(blob, {
      type: "text/plain",
    });
    a.download = `${subscription.companyName}-campaign:${subscription.name}-start:${values.startDate}-end:${values.startDate}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const today = new Date().toISOString().split("T")[0];
  //Extra subscription stats can go here
  return (
    <Card fluid style={{ padding: "10px" }}>
      <div>Subscription Data</div>
      <Formik
        initialValues={{
          subscriptionGuid: subscriptionId,
          startDate: today,
          endDate: today,
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="subscription-form">
            <div>
              <div className="date-selection-container">
                <div>
                  <span>Start Date</span>
                  <input
                    type="date"
                    id="start"
                    name="Start"
                    min="2000-01-01"
                    label="Start Date"
                    value={values.startDate}
                    max={today}
                    onChange={(e) => setFieldValue(`startDate`, e.target.value)}
                  />
                </div>
                <div>
                  <span>End Date</span>
                  <input
                    type="date"
                    id="end"
                    name="End"
                    min="2000-01-01"
                    max={today}
                    value={values.endDate}
                    onChange={(e) => setFieldValue(`endDate`, e.target.value)}
                  />
                </div>
              </div>
            </div>
            <IconButton
              title="Export"
              text="Export"
              onClick={() => {
                exportData(values);
              }}
              disabled={
                values.startDate === "" ||
                values.endDate === "" ||
                values.startDate > values.endDate
              }
              color="taupe"
            />
          </Form>
        )}
      </Formik>
    </Card>
  );
};
export default ExportSubscriptionData;
