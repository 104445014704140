import { memo, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useHistory } from "react-router-dom";
import { UserContext } from "../context-provider/UserProvider";
import { tokenIsExpired } from "../utils/jwt";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function ProtectedRoute({ children, allowed, redirectPath, ...rest }) {
  const { isLoggedIn, setUser, logout, setCompanyId, setIsLoggedIn } =
    useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const sessionData = localStorage.getItem("userProfile");
    try {
      const userProfile = JSON.parse(sessionData);
      if (!tokenIsExpired(userProfile.token)) {
        setUser(userProfile);
        setIsLoggedIn(true);
        setCompanyId(userProfile.companies[0]?.guid);
        if (location.pathname === "/login") {
          history.push("/");
        } else history.push(location.pathname);
      } else {
        logout();
      }
    } catch (e) {
      console.error(e);
      logout();
    }
  }, [history]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

ProtectedRoute.defaultProps = {
  redirectPath: "/dashboard",
};

ProtectedRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default memo(ProtectedRoute);
