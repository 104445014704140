import React, { useEffect } from "react";
import { Form, Modal, Dropdown, Table } from "semantic-ui-react";
import { Formik } from "formik";
import useFetch from "../../../hooks/use-fetch";
import IconButton from "../../ui/button";

const CreateAnswerModal = ({ tasks, getData, questionGuid }) => {
  const api = useFetch();
  const [open, setOpen] = React.useState(false);

  const saveAnswer = async (values) => {
    await api.fetchData(`api/subscriptions/create_answer`, "POST", {
      ...values,
      questionGuid,
    });
    getData();
    setOpen(false);
  };

  const initialState = {
    text: "",
    tasks: [],
  };

  return (
    <div>
      <Modal
        id="create-answer-modal"
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeOnDimmerClick={false}
        size="large"
        trigger={<IconButton title="Add" text="Add Answer" color="taupe" />}
      >
        <Modal.Header>
          <h2>New Answer</h2>
        </Modal.Header>

        <Modal.Content>
          <Modal.Description>
            <Formik initialValues={initialState}>
              {({ values, setFieldValue }) => (
                <Form>
                  <div style={{ marginBottom: "10px" }}>
                    <Table compact celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Answers</Table.HeaderCell>
                          <Table.HeaderCell>Tasks Generated</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Row>
                        <Table.Cell width={7}>
                          <Form.Input
                            placeholder="Answer Text"
                            id="answers"
                            onChange={(e) =>
                              setFieldValue(`text`, e.target.value)
                            }
                            value={values.answerText}
                            name="answers"
                          />
                        </Table.Cell>

                        <Table.Cell width={9}>
                          <div className="field">
                            <Dropdown
                              value={values.tasks.map((x) => x.id)}
                              multiple
                              selection
                              onChange={(e, data) => {
                                const newValue = data.value.map((id) => {
                                  return {
                                    id,
                                    name: tasks.find((x) => x.id === id).name,
                                  };
                                });
                                setFieldValue(`tasks`, newValue);
                              }}
                              options={tasks.map((t) => {
                                return {
                                  key: t.id,
                                  text: t.name,
                                  value: t.id,
                                };
                              })}
                            />
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </Table>
                  </div>

                  <Modal.Actions>
                    <IconButton
                      title="Cancel"
                      text="Cancel"
                      color="taupe"
                      onClick={() => setOpen(false)}
                    />

                    <IconButton
                      title="Save"
                      text="Save"
                      disabled={values.answerText === ""}
                      onClick={() => {
                        saveAnswer(values);
                      }}
                    />
                  </Modal.Actions>
                </Form>
              )}
            </Formik>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default CreateAnswerModal;
