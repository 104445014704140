import Companies from "../components/companies/index";
import CompanyView from "../components/companies/company-details";
import AddCompanyForm from "../components/companies/add-company-form";

const CompanyRoutes = [
  {
    path: "/companies",
    component: Companies,
    exact: true,
  },
  {
    path: "/companies/add",
    component: AddCompanyForm,
    exact: true,
  },
  {
    path: "/companies/:companyId",
    component: CompanyView,
    exact: true,
  },
];

export default CompanyRoutes;
