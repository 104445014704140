import useFetch from "../../hooks/use-fetch";
import CompaniesTable from "./company-table";
import IconButton from "../ui/button";
import { useHistory } from "react-router-dom";
import "./style.scss";
import TableProvider from "../../context-provider/TableProvider";

const CompaniesView = () => {
  const history = useHistory();

  const api = useFetch({
    companies: [
      {
        guid: "",
        companyName: "",
        active: "",
        accountNumber: "",
        twilioPhone: "",
      },
    ],
  });

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1em 0em",
        }}
      >
        <h2>Companies</h2>

        <div>
          <IconButton
            title="Add"
            text="New Company"
            onClick={() => {
              history.push("/companies/add");
            }}
            icon="add"
          />
        </div>
      </div>
      <TableProvider>
        <CompaniesTable api={api} />
      </TableProvider>
    </div>
  );
};

export default CompaniesView;
