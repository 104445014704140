import { Formik, Form, FieldArray } from "formik";
import { Button, Dropdown } from "semantic-ui-react";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useFetch from "../../../../hooks/use-fetch";
import * as Yup from "yup";
import "./style.scss";
import { useState } from "react";
import IconButton from "../../../ui/button";

const EditOutreachCycle = ({
  outreachCycles,
  setEditing,
  campaigns,
  subscriptionId,
  getOutreachCycle,
}) => {
  const api = useFetch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const normalizeOrder = (itemRemoved, list) => {
    const result = list
      .filter((x, index) => index !== itemRemoved)
      .map((x, i) => {
        if (i > itemRemoved - 1) return { ...x, cycleCount: x.cycleCount - 1 };
        else return x;
      });

    return result;
  };

  const submitValues = async (values) => {
    //TODO: Give better feedback on why I won't let them submit here
    if (values.cycles.some((x) => x.requestGuid === "")) return;
    setIsSubmitting(true);
    await api.fetchData("api/subscriptions/update_outreach_cycle", "POST", {
      subscriptionGuid: subscriptionId,
      cycles: values.cycles,
    });
    await getOutreachCycle(subscriptionId);
    setEditing(false);
  };

  // Form with dropdowns to select the campaign and where in the outreach cycle it goes.
  // Draggable would be nice to have again
  return (
    <Formik
      initialValues={{
        cycles: outreachCycles.map((x) => {
          return { cycleCount: x.cycleCount, requestGuid: x.requestGuid };
        }),
      }}
      onSubmit={async (values) => {
        submitValues(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <FieldArray
            name="cycles"
            render={(arrayHelper) => (
              <div>
                <div className="space-between-container">
                  <h3>Outreach Cycle</h3>
                  <Button
                    style={{ width: "30%" }}
                    size="tiny"
                    type="button"
                    disabled={isSubmitting}
                    fluid
                    onClick={() => {
                      arrayHelper.push({
                        cycleCount: values.cycles.length + 1,
                        requestGuid: "",
                      });
                    }}
                  >
                    Add Cycle
                  </Button>
                  {/* <IconButton
                    title="Add Cycle"
                    text="Add Cycle"
                    onClick={() => {
                      arrayHelper.push({
                        cycleCount: values.cycles.length + 1,
                        requestGuid: "",
                      });
                    }}
                    color="taupe"
                  /> */}
                </div>
                {values.cycles.map((cycle, i) => {
                  return (
                    <div key={i} className="outreach-cycle-group">
                      <h3>{i + 1}.</h3>
                      <Dropdown
                        placeholder="Choose campaign"
                        selection
                        fluid
                        style={{ width: "80%" }}
                        value={cycle.requestGuid}
                        options={[
                          { key: -1, text: "", value: "" },
                          ...campaigns,
                        ]}
                        onChange={(e, data) => {
                          setFieldValue(
                            `cycles.${[i]}.requestGuid`,
                            data.value
                          );
                        }}
                      />
                      <div>
                        <Button
                          type="button"
                          icon="cancel"
                          disabled={isSubmitting}
                          size="small"
                          onClick={() => {
                            const newList = normalizeOrder(i, values.cycles);
                            setFieldValue(`cycles`, newList);
                          }}
                        ></Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          />

          <div className="cycle-button-group">
            <IconButton
              title="Cancel"
              text="Cancel"
              onClick={() => setEditing(false)}
              color="taupe"
              disabled={isSubmitting}
            />

            <IconButton
              type="submit"
              title="Save"
              text="Save"
              disabled={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditOutreachCycle;
