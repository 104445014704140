import React from "react";
import { Form, Table, Card, Label, Divider } from "semantic-ui-react";
import { Formik } from "formik";
import useFetch from "../../../hooks/use-fetch";
import IconButton from "../../ui/button";
import ConfirmDeleteButtonGroup from "../../ui/confirm-delete";
import EditAnswerModal from "./edit-answer-modal";
import { useHistory } from "react-router-dom";
import CreateAnswerModal from "./create-answer-modal";
import CreateQuestionModal from "../subscription-details/create-question-modal";

const EditQuestionForm = ({ step, tasks, getData, questionGuid }) => {
  const api = useFetch();
  const history = useHistory();

  const deleteQuestion = async (questionGuid) => {
    await api.fetchData(
      `api/subscriptions/deactivate_question`,
      "POST",
      questionGuid
    );
    history.push(`/subscriptions/${step.subscriptionGuid}`);
  };

  const updateQuestion = async (values) => {
    //Not actually a route yet
    const response = await api.fetchData(
      `api/subscriptions/edit_question`,
      "POST",
      {
        questionText: values.questionText,
        questionGuid,
      }
    );
    //Going to need to push to new question otherwise they will continue to edit the inactive question
    history.push(`/subscriptions/questions/${response.guid}`);
  };

  return (
    <Card fluid>
      <Card.Header className="card-header">
        <div className="space-between-container">
          <h2>Edit Question</h2>

          <ConfirmDeleteButtonGroup
            confirmAction={() => deleteQuestion(questionGuid)}
          />
        </div>
      </Card.Header>

      <Card.Content>
        <div>
          <Formik initialValues={step}>
            {({ values, setFieldValue, handleBlur }) => (
              <Form>
                <Form.Input
                  id="request"
                  label="Question"
                  value={values.questionText}
                  placeholder="Question Text"
                  onChange={(e) =>
                    setFieldValue(`questionText`, e.target.value)
                  }
                  onBlur={handleBlur}
                />

                {step.questionText !== values.questionText ? (
                  <IconButton
                    title="Save"
                    text="Save Changes"
                    onClick={() => {
                      updateQuestion(values);
                    }}
                  />
                ) : (
                  ""
                )}
                <Divider />
              </Form>
            )}
          </Formik>
          <Card.Content style={{ marginBottom: "10px" }}>
            <CreateAnswerModal
              tasks={tasks}
              getData={getData}
              questionGuid={questionGuid}
            />
            <Table compact celled>
              <Table.Header>
                <Table.Row>
                  {/* <Table.HeaderCell /> */}
                  <Table.HeaderCell>Edit</Table.HeaderCell>
                  <Table.HeaderCell>Answers</Table.HeaderCell>
                  <Table.HeaderCell>Tasks Generated</Table.HeaderCell>
                  {/* <Table.HeaderCell>Delete</Table.HeaderCell> */}
                </Table.Row>
              </Table.Header>
              {step.answers.map((answer, iA) => {
                return (
                  <Table.Row key={`answer-${iA}`}>
                    <Table.Cell width={1}>
                      <EditAnswerModal
                        answer={answer}
                        tasks={tasks}
                        getData={getData}
                        questionGuid={questionGuid}
                      />
                    </Table.Cell>
                    <Table.Cell width={7}>
                      <div>{answer.answerText}</div>
                    </Table.Cell>
                    <Table.Cell width={6}>
                      <div className="tasks-label-container">
                        {answer.tasks.map((task) => (
                          <Label
                            key={task.id}
                            style={{ margin: "2px 0px" }}
                            size="mini"
                            color="red"
                          >
                            {task.name}
                          </Label>
                        ))}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table>
          </Card.Content>

          <div></div>
        </div>
      </Card.Content>
    </Card>
  );
};

export default EditQuestionForm;
