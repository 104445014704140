import React, { useState, createContext } from "react";

export const TableContext = createContext();

function TableProvider(props) {
  const [activePage, setActivePage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  return (
    <TableContext.Provider
      value={{
        activePage,
        setActivePage,
        recordsPerPage,
        setRecordsPerPage,
      }}
    >
      {props.children}
    </TableContext.Provider>
  );
}

export default TableProvider;
