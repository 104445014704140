import CustomerQuestionnaires from "../components/questionnaires";
import ThankYouView from "../components/questionnaires/thank-you";

const QuestionnaireRoutes = [
  {
    path: "/questionnaires/:token",
    component: CustomerQuestionnaires,
    exact: true,
  },
  {
    path: "/thanks",
    component: ThankYouView,
    exact: true,
  },
];

export default QuestionnaireRoutes;
