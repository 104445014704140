import useFetch from "../../../hooks/use-fetch";
import { Button, Form } from "semantic-ui-react";
import { Formik } from "formik";
import "./style.scss";
import { useHistory } from "react-router-dom";
import CompanySelect from "../../company-select";
import { useContext } from "react";
import { UserContext } from "../../../context-provider/UserProvider";
import IconButton from "../../ui/button";

const AddCustomerForm = () => {
  const history = useHistory();
  const api = useFetch();
  const { companyId } = useContext(UserContext);

  const saveCustomer = async (values) => {
    const response = await api.fetchData(
      "api/customers/create_customer",
      "PUT",
      values
    );
    history.push(`${response.guid}`);
  };

  return (
    <>
      <Formik
        initialValues={{
          active: true,
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          companyGuid: companyId,
          customerGuid: "",
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="add-customer-form">
            <div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Form.Input
                  label="First Name"
                  onChange={(e) => setFieldValue(`firstName`, e.target.value)}
                />
                <Form.Input
                  label="Last Name"
                  onChange={(e) => setFieldValue(`lastName`, e.target.value)}
                />
              </div>
              <Form.Input
                label="Email"
                onChange={(e) => setFieldValue(`email`, e.target.value)}
              />
              <Form.Input
                label="Phone Number"
                onChange={(e) => setFieldValue(`phoneNumber`, e.target.value)}
                maxLength="10"
              />
              <div className="field">
                {/*this just exists to get semantic styling*/}
                <label>Company</label>
                <CompanySelect
                  setCompanyId={setFieldValue}
                  fieldName={`companyGuid`}
                />
              </div>
              <IconButton
                title="Submit"
                text="Submit"
                onClick={async () => {
                  saveCustomer(values);
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddCustomerForm;
