import React, { useState, createContext } from "react";
import { HOST } from "../environment";

// import { Loader } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";

export const UserContext = createContext();

function UserProvider(props) {
  // const userProfile = sessionStorage.getItem("userProfile");
  // const [isLoggedIn, setIsLoggedIn] = useState(userProfile != null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const history = useHistory();
  const location = useLocation();
  const [companyId, setCompanyId] = useState();

  const login = (values) => {
    fetch(`${HOST}/api/users/login`, {
      type: "API",
      body: JSON.stringify(values),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
        setIsLoggedIn(true);
        setCompanyId(data.companies[0]?.guid);
        localStorage.setItem("userProfile", JSON.stringify(data));
        history.push("/");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const logout = () => {
    //Probably going to do some back end logic too
    localStorage.clear();
    setIsLoggedIn(false);
    history.push("/login");
  };

  //   const saveUser = (userProfile) => {
  //     return getToken().then((token) =>
  //       fetch(apiUrl, {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(userProfile),
  //       }).then((resp) => resp.json())
  //     );
  //   };

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        user,
        setCompanyId,
        companyId,
        setUser,
        setIsLoggedIn,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export default UserProvider;
