import useFetch from "../../../hooks/use-fetch";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Loader, Divider, Grid, Modal, Button } from "semantic-ui-react";
import EditSubscription from "./edit-subscription";
import RequestCards from "./request-cards";
import OutreachCycle from "./outreach-cycle";
import AssignPatientsModal from "./assign-patients";
import ExportSubscriptionData from "./export-campaign-data";
import IconButton from "../../ui/button";

const SubscriptionDetails = () => {
  const api = useFetch();
  const [open, setOpen] = useState(false);
  const [startedCalls, setStartedCalls] = useState(false);

  const [subscription, setSubscription] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [customers, setCustomers] = useState([]);
  const { subscriptionId } = useParams();

  const getSubscriptionDetails = async () => {
    const response = await api.fetchData(
      `api/subscriptions/subscription_details/${subscriptionId}`
    );
    setSubscription(response);
    // Should probably try catch in case one fails
    setIsFetching(false);
  };

  const getTasks = async () => {
    const taskResponse = await api.fetchData(`api/tasks/all_tasks`);
    setTasks(taskResponse);
  };
  const getPossibleCustomers = async () => {
    //Gets list of customers that can be called with the test campaign dropdown
    const customerResponse = await api.fetchData(
      "api/customers/campaign_customers",
      "POST",
      subscriptionId
    );

    setCustomers(customerResponse);
  };

  const kickoffIVR = () => {
    setOpen(false);
    setStartedCalls(true);
    api.fetchData("api/ivr/start_ivr", "POST", subscriptionId);
  };

  useEffect(() => {
    getTasks();
    getSubscriptionDetails();
    getPossibleCustomers();
  }, []);

  return (
    <>
      {!isFetching ? (
        <div style={{ width: "100%" }}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <h2>{subscription.companyName}</h2>
                <EditSubscription
                  subscription={subscription}
                  subscriptionId={subscriptionId}
                  getSubscriptionDetails={getSubscriptionDetails}
                />
                <AssignPatientsModal
                  subscriptionId={subscriptionId}
                  getSubscriptionDetails={getSubscriptionDetails}
                  getPossibleCustomers={getPossibleCustomers}
                />
                <Modal
                  id="start-ivr-modal"
                  closeIcon
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  open={open}
                  closeOnDimmerClick={false}
                  size="large"
                  trigger={
                    <IconButton
                      title="Kickoff IVR (Call Everyone)"
                      text="Kickoff IVR"
                      disabled={startedCalls}
                    />
                  }
                >
                  <div className="card-header">
                    <div className="space-between-container">
                      <h2>Start the IVR?</h2>
                    </div>
                  </div>
                  <Modal.Content>
                    This will contact{" "}
                    <b>all {subscription.remainingSubscribers}</b> subscribers
                    that have not yet given a response.
                  </Modal.Content>
                  <Modal.Actions>
                    <IconButton
                      title="Cancel"
                      text="Cancel"
                      color="taupe"
                      onClick={() => setOpen(false)}
                    />

                    <IconButton
                      title="Confirm"
                      text="Confirm"
                      onClick={() => {
                        kickoffIVR();
                      }}
                    />
                  </Modal.Actions>
                </Modal>
                {/* <IconButton
                  title="Start Campaign"
                  text="Kickoff IVR (Call All)"
                  onClick={() => kickoffIVR()}
                  color="taupe"
                /> */}

                <div>Total Subscribers: {subscription.subscribers}</div>
                <div>
                  Subscribers Without Responses:
                  {subscription.remainingSubscribers}
                </div>
              </Grid.Column>

              <Grid.Column width={8}>
                <ExportSubscriptionData
                  subscriptionId={subscriptionId}
                  subscription={subscription}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider />
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <RequestCards
                  subscription={subscription}
                  getSubscriptionDetails={getSubscriptionDetails}
                  tasks={tasks}
                  customers={customers}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <OutreachCycle
                  subscriptionId={subscriptionId}
                  campaigns={subscription.requests.map((x, i) => {
                    return { key: i, text: x.name, value: x.guid };
                  })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      ) : (
        <Loader active />
      )}
    </>
  );
};

export default SubscriptionDetails;
