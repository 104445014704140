import React, { useEffect, useState, useContext } from "react";
import { Table, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./style.scss";
import { TableContext } from "../../context-provider/TableProvider";
import Paginate from "../ui/pagination";

const TasksTable = ({ api, companyId }) => {
  const { activePage, recordsPerPage } = useContext(TableContext);

  useEffect(() => {
    if (companyId) {
      api.fetchData("api/tasks/tasks_list", "POST", {
        companyGuid: companyId,
        recordsPerPage,
        activePage,
      });
    }
  }, [companyId, activePage]);

  return (
    <div>
      <Paginate totalRecords={api.data.total} />
      <Table celled padded>
        <Table.Header>
          <Table.Row className="semantic-header-row">
            <Table.HeaderCell>Tasks</Table.HeaderCell>
            <Table.HeaderCell>Cutomer</Table.HeaderCell>
            <Table.HeaderCell>Date Created</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {!api.isFetching ? (
          <Table.Body>
            {api.data.tasks.map((x, i) => {
              return (
                <Table.Row key={x.guid + i}>
                  <Table.Cell>
                    <Link to={`/customers/${x.customerGuid}/${x.requestGuid}`}>
                      <h4 className="text-dark">{x.requestName}</h4>
                    </Link>
                    <div>
                      {x.tasksCreated
                        ? x.tasksCreated.map((y, iT) => (
                            <li key={`task-${iT}`}>{y}</li>
                          ))
                        : ""}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      className="text-dark"
                      to={`/customers/${x.customerGuid}`}
                    >
                      {x.firstName + " " + x.lastName}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{x.responseDate}</Table.Cell>
                  <Table.Cell>
                    <p className={x.resolved ? "resolved" : "not-resolved"}>
                      {x.resolved ? "Resolved" : "Not Resolved"}
                    </p>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        ) : (
          <Loader active />
        )}
      </Table>
    </div>
  );
};
export default TasksTable;
