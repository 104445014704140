import { Formik } from "formik";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Label,
  Divider,
  Table,
  Dropdown,
  Popup,
  Icon,
} from "semantic-ui-react";
import { UserContext } from "../../../context-provider/UserProvider";
import useFetch from "../../../hooks/use-fetch";
import IconButton from "../../ui/button";
import "../style.scss";
import CreateQuestionModal from "./create-question-modal";
import EditCallbackNumber from "./edit-callback-number";
import EditEnding from "./edit-ending";
import EditOpening from "./edit-opening";
import TestIVRCampaign from "./test-campaign";

const RequestDetails = ({
  request,
  // activeRequest,
  // setActiveRequest,
  setEditing,
  i,
  customers,
  tasks,
  getSubscriptionDetails,
}) => {
  const startEditing = (i) => {
    // setActiveRequest(i);
    setEditing(i);
  };
  const api = useFetch();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [nextQuestionOptions, setNextQuestionOptions] = useState([]);

  const updateNextQuestion = async (payload) => {
    console.log(payload);
    await api.fetchData(
      "api/subscriptions/update_next_question",
      "POST",
      payload
    );
    getSubscriptionDetails();
  };

  const blankOption = {
    key: "Totally Unique Key",
    text: "",
    value: null,
  };

  const truncate = (str, n) => {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  };

  useEffect(() => {
    var questions = request.steps.map((question) => {
      return {
        key: question.guid,
        text: `${question.orderBy}: ${truncate(question.question, 60)}`,
        value: question.guid,
        orderBy: question.orderBy,
      };
    });

    questions = [blankOption, ...questions];
    setNextQuestionOptions(questions);
  }, [request]);

  return (
    <div>
      <CreateQuestionModal
        requestGuid={request.guid}
        getSubscriptionDetails={getSubscriptionDetails}
        tasks={tasks}
      />
      <EditOpening
        request={request}
        getSubscriptionDetails={getSubscriptionDetails}
      />
      <div>
        {request.steps.map((step) => (
          <div className="campaign-question-group" key={step.guid}>
            <div className="space-between-container">
              <h3>
                Question {step.orderBy}: {step.question}
              </h3>

              <IconButton
                onClick={() =>
                  history.push(`/subscriptions/questions/${step.guid}`)
                }
                title="Edit"
                text="Edit Question"
              />
            </div>
            <Divider fitted />

            <Table compact celled>
              <Table.Header>
                <Table.Row>
                  {/* <Table.HeaderCell /> */}
                  <Table.HeaderCell>Answers</Table.HeaderCell>
                  <Table.HeaderCell>Tasks Generated</Table.HeaderCell>
                  <Table.HeaderCell className="space-between-container">
                    <div>Next Question</div>
                    <Popup
                      position="top center"
                      trigger={<Icon circular name="question" />}
                    >
                      Answers will by default go to the next question. You can
                      choose to have specific answers skip questions here. Make
                      sure to save your changes!
                    </Popup>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {step.answers.map((answer, iA) => {
                return (
                  <Table.Row key={`answer-${iA}`}>
                    <Table.Cell width={6}>
                      <div>{answer.text}</div>
                    </Table.Cell>
                    <Table.Cell width={6}>
                      <div className="tasks-label-container">
                        {answer.tasks.map((task) => (
                          <Label
                            key={task.id}
                            style={{ margin: "2px 0px" }}
                            size="mini"
                            color="red"
                          >
                            {task.name}
                          </Label>
                        ))}
                      </div>
                    </Table.Cell>
                    <Table.Cell width={6}>
                      <Formik
                        initialValues={{
                          answerguid: answer.guid,
                          nextQuestionGuid: answer.nextQuestionGuid,
                        }}
                      >
                        {({ values, setFieldValue }) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Dropdown
                              style={{
                                marginRight: "5px",
                              }}
                              // className="overflow"
                              placeholder="Jump to question..."
                              fluid
                              selection
                              // style={{ width: 250 }}
                              value={values.nextQuestionGuid}
                              options={nextQuestionOptions.filter(
                                // Don't allow user to pick the same question or any questions before it
                                (x) => x.orderBy > step.orderBy || !x.value
                              )}
                              onChange={(e, data) => {
                                setFieldValue("nextQuestionGuid", data.value);
                              }}
                              // onBlur={() => {
                              //   // TODO: Need to compare what is answered with what the old value was to determine if the request should happen
                              //   updateNextQuestion({
                              //     answerChosenGuid: values.answerguid,
                              //     nextQuestionGuid: values.nextQuestionGuid,
                              //     requestGuid: request.guid,
                              //     currentQuestionGuid: step.guid,
                              //   });
                              // }}
                            />

                            {values.nextQuestionGuid !==
                            answer.nextQuestionGuid ? (
                              <Icon
                                circular
                                color="blue"
                                link
                                name="save"
                                onClick={() => {
                                  updateNextQuestion({
                                    answerChosenGuid: values.answerguid,
                                    nextQuestionGuid: values.nextQuestionGuid,
                                    requestGuid: request.guid,
                                    currentQuestionGuid: step.guid,
                                  });
                                }}
                              />
                            ) : (
                              <Icon circular />
                            )}
                          </div>
                        )}
                      </Formik>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table>
          </div>
        ))}
        <EditEnding
          request={request}
          getSubscriptionDetails={getSubscriptionDetails}
        />

        {user.type === "Admin" && (
          <EditCallbackNumber
            request={request}
            getSubscriptionDetails={getSubscriptionDetails}
          />
        )}

        <TestIVRCampaign requestGuid={request.guid} customers={customers} />
      </div>
    </div>
  );
};
export default RequestDetails;
