import jwtDecode from "jwt-decode";

export const decodeToken = (token, decoder = jwtDecode) => {
  const decodedValue = decoder(token);
  return decodedValue;
};

export const tokenIsExpired = token => {
  const { exp } = decodeToken(token);
  // exp should be Epoch timestamp in seconds
  const currentTime = Date.now();
  return exp * 1000 < currentTime;
};
