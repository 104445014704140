import React from "react";
import { Form, Modal, Dropdown, Table } from "semantic-ui-react";
import { Formik, FieldArray } from "formik";
import useFetch from "../../../hooks/use-fetch";
import IconButton from "../../ui/button";

const CreateQuestionModal = ({
  requestGuid,
  getSubscriptionDetails,
  tasks,
}) => {
  const api = useFetch();
  const [open, setOpen] = React.useState(false);
  const initialState = {
    requestGuid: requestGuid,
    questionText: "",
    answers: [
      { text: "", tasks: [] },
      { text: "", tasks: [] },
    ],
  };

  const saveNewQuestion = async (values) => {
    await api.fetchData(`api/subscriptions/create_question`, "POST", values);
    await getSubscriptionDetails();
    setOpen(false);
  };

  return (
    <div>
      <Modal
        id="create-question-modal"
        closeIcon
        closeOnDimmerClick={false}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="large"
        trigger={<IconButton title="Add" text="Add Question" color="taupe" />}
      >
        <Modal.Header>New Question</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Formik initialValues={initialState}>
              {({ values, setFieldValue, handleBlur }) => (
                <Form>
                  <Form.Input
                    id="request"
                    label="Question"
                    placeholder="Question Text"
                    onChange={(e) =>
                      setFieldValue(`questionText`, e.target.value)
                    }
                    onBlur={handleBlur}
                  />

                  <FieldArray
                    name={`answers`}
                    render={(innerHelper) => (
                      <div style={{ marginBottom: "10px" }}>
                        <IconButton
                          title="Add"
                          text="Add Answer"
                          color="pink"
                          onClick={() => {
                            if (values.answers.length < 6) {
                              innerHelper.push({
                                text: "",
                                tasks: [],
                              });
                            }
                          }}
                        />
                        <IconButton
                          title="Remove"
                          text="Remove Answer"
                          color="pink"
                          onClick={() => {
                            innerHelper.pop();
                          }}
                        />
                        {values.answers.length === 6 && (
                          <div>Maximum amount of answers.</div>
                        )}
                        <Table compact celled>
                          <Table.Header>
                            <Table.Row>
                              {/* <Table.HeaderCell /> */}
                              <Table.HeaderCell>Answers</Table.HeaderCell>
                              <Table.HeaderCell>
                                Tasks Generated
                              </Table.HeaderCell>
                              {/* <Table.HeaderCell>Dele</Table.HeaderCell> */}
                            </Table.Row>
                          </Table.Header>
                          {values.answers.map((answer, iA) => {
                            return (
                              <Table.Row key={`answer-${iA}`}>
                                <Table.Cell width={7}>
                                  <Form.Input
                                    placeholder="Answer Text"
                                    id="answers"
                                    onChange={(e) =>
                                      setFieldValue(
                                        `answers.${[iA]}.text`,
                                        e.target.value
                                      )
                                    }
                                    value={answer.text}
                                    name="answers"
                                  />
                                </Table.Cell>
                                <Table.Cell width={9}>
                                  <div className="field">
                                    <Dropdown
                                      value={answer.tasks.map((x) => x.id)}
                                      key={`dropdown-${iA}`}
                                      multiple
                                      selection
                                      onChange={(e, data) => {
                                        const newValue = data.value.map(
                                          (id) => {
                                            return {
                                              id,
                                              name: tasks.find(
                                                (x) => x.id === id
                                              ).name,
                                            };
                                          }
                                        );
                                        setFieldValue(
                                          `answers.${[iA]}.tasks`,
                                          newValue
                                        );
                                      }}
                                      options={tasks.map((t) => {
                                        return {
                                          key: t.id,
                                          // text: `${t.name} - ${t.item}`, Need to find a way to display all task info if needed
                                          text: t.name,
                                          value: t.id,
                                        };
                                      })}
                                    />
                                  </div>
                                </Table.Cell>
                                {/* <Table.Cell width={1}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                  <Icon
                                    style={{
                                      margin: "0 0 1em",
                                      cursor: "pointer",
                                    }} // To match the margin from "field" class
                                    name="cancel"
                                    onClick={() => {
                                      innerHelper.remove(iA);
                                    }}
                                  />
                                  </div>
                                </Table.Cell> */}
                              </Table.Row>
                            );
                          })}
                        </Table>
                      </div>
                    )}
                  />

                  <Modal.Actions>
                    <IconButton
                      title="Cancel"
                      text="Cancel"
                      color="taupe"
                      onClick={() => setOpen(false)}
                    />

                    <IconButton
                      title="Save"
                      text="Save"
                      onClick={() => {
                        saveNewQuestion(values);
                      }}
                    />
                  </Modal.Actions>
                </Form>
              )}
            </Formik>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default CreateQuestionModal;
