import { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import useFetch from "../../hooks/use-fetch";
import { useContext } from "react";
import { UserContext } from "../../context-provider/UserProvider";
import CompanySelect from "../company-select";
import UsersTable from "./user-table";
import IconButton from "../ui/button";
import { useHistory } from "react-router-dom";
import TableProvider from "../../context-provider/TableProvider";

const UsersView = () => {
  const { companyId, setCompanyId } = useContext(UserContext);
  const history = useHistory();

  const api = useFetch({
    users: [
      {
        guid: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        type: "",
      },
    ],
  });

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1em 0em",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <h2 style={{ marginRight: "15px" }}>Users</h2>
          <CompanySelect setCompanyId={setCompanyId} />
        </div>

        <div>
          <IconButton
            title="Add"
            text="New User"
            onClick={() => {
              history.push("/users/add");
            }}
            icon="add"
          />
        </div>
      </div>

      <TableProvider>
        <UsersTable api={api} companyId={companyId} />
      </TableProvider>
    </div>
  );
};

export default UsersView;
