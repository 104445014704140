import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useFetch from "../../../hooks/use-fetch";
import EditQuestionForm from "./edit-question-form";

const EditQuestionView = () => {
  const { questionId } = useParams();
  const [loading, setLoading] = useState(true);
  const api = useFetch({
    step: [
      {
        guid: questionId,
        text: "",
        answers: [{ active: null, guid: "", orderBy: "", text: "", tasks: [] }],
      },
    ],
  });
  const taskApi = useFetch({
    contact: "",
    id: 0,
    item: "",
    name: "",
  });

  const getData = async () => {
    await api.fetchData(`api/subscriptions/get_question/${questionId}`);
    setLoading(false);
  };

  const getTasks = () => {
    taskApi.fetchData(`api/tasks/all_tasks`);
  };

  useEffect(() => {
    getData();
  }, [questionId]);

  useEffect(() => {
    getTasks();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {!loading ? (
        <EditQuestionForm
          step={api.data}
          tasks={taskApi.data}
          getData={getData}
          questionGuid={questionId}
        />
      ) : (
        ""
      )}
      <div>Offer previously used questions down here</div>
    </div>
  );
};

export default EditQuestionView;
