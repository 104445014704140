import React, { useEffect } from "react";
import { Form, Modal, Dropdown, Table } from "semantic-ui-react";
import { Formik } from "formik";
import useFetch from "../../../hooks/use-fetch";
import IconButton from "../../ui/button";
import ConfirmDeleteButtonGroup from "../../ui/confirm-delete";

const EditAnswerModal = ({ answer, tasks, getData, questionGuid }) => {
  const api = useFetch();
  const [open, setOpen] = React.useState(false);

  const saveAnswerChanges = async (values) => {
    await api.fetchData(`api/subscriptions/edit_answer`, "POST", {
      ...values,
      questionGuid,
    });
    getData();
    setOpen(false);
  };

  const deleteAnswer = async (answer) => {
    await api.fetchData(`api/subscriptions/deactivate_answer`, "PUT", {
      answerGuid: answer.guid,
      questionGuid: questionGuid,
    });
    await getData();
    setOpen(false);
  };

  return (
    <div>
      <Modal
        id="edit-answer-modal"
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeOnDimmerClick={false}
        size="large"
        trigger={<IconButton title="Edit" icon="edit" />}
      >
        <div className="card-header">
          <div className="space-between-container">
            <h2>Edit Answer</h2>

            <ConfirmDeleteButtonGroup
              confirmAction={() => deleteAnswer(answer)}
            />
          </div>
        </div>

        <Modal.Content>
          <Modal.Description>
            <Formik initialValues={answer}>
              {({ values, setFieldValue, handleBlur }) => (
                <Form>
                  <div style={{ marginBottom: "10px" }}>
                    <Table compact celled>
                      <Table.Header>
                        <Table.Row>
                          {/* <Table.HeaderCell /> */}
                          <Table.HeaderCell>Answers</Table.HeaderCell>
                          <Table.HeaderCell>Tasks Generated</Table.HeaderCell>
                          {/* <Table.HeaderCell>Dele</Table.HeaderCell> */}
                        </Table.Row>
                      </Table.Header>

                      <Table.Row>
                        <Table.Cell width={7}>
                          <Form.Input
                            placeholder="Answer Text"
                            id="answers"
                            onChange={(e) =>
                              setFieldValue(`answerText`, e.target.value)
                            }
                            value={values.answerText}
                            name="answers"
                          />
                        </Table.Cell>
                        <Table.Cell width={9}>
                          <div className="field">
                            <Dropdown
                              value={values.tasks.map((x) => x.id)}
                              multiple
                              selection
                              onChange={(e, data) => {
                                const newValue = data.value.map((id) => {
                                  return {
                                    id,
                                    name: tasks.find((x) => x.id === id).name,
                                  };
                                });
                                setFieldValue(`tasks`, newValue);
                              }}
                              options={tasks.map((t) => {
                                return {
                                  key: t.id,
                                  // text: `${t.name} - ${t.item}`, Need to find a way to display all task info if needed
                                  text: t.name,
                                  value: t.id,
                                };
                              })}
                            />
                          </div>
                        </Table.Cell>
                        {/* <Table.Cell width={1}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                  <Icon
                                    style={{
                                      margin: "0 0 1em",
                                      cursor: "pointer",
                                    }} // To match the margin from "field" class
                                    name="cancel"
                                    onClick={() => {
                                      innerHelper.remove(iA);
                                    }}
                                  />
                                  </div>
                                </Table.Cell> */}
                      </Table.Row>
                    </Table>
                  </div>

                  <Modal.Actions>
                    <IconButton
                      title="Cancel"
                      text="Cancel"
                      color="taupe"
                      onClick={() => setOpen(false)}
                    />

                    {JSON.stringify(answer) !== JSON.stringify(values) ? (
                      <IconButton
                        title="Save"
                        text="Save"
                        onClick={() => {
                          saveAnswerChanges(values);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Modal.Actions>
                </Form>
              )}
            </Formik>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default EditAnswerModal;
