import { Image, Menu } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../context-provider/UserProvider";
import { useContext } from "react";
import "./style.scss";
import logoLight from "../assets/images/logoLight.svg";

const SemanticNavBar = () => {
  const history = useHistory();
  const { logout } = useContext(UserContext);
  const leftItems = [
    {
      as: "a",
      content: "Home",
      key: "home",
      onClick: () => history.push("/"),
    },
    {
      as: "a",
      content: "Subscriptions",
      key: "subscriptions",
      onClick: () => history.push("/subscriptions"),
    },
    {
      as: "a",
      content: "Customers",
      key: "customers",
      onClick: () => history.push("/customers"),
    },
    {
      as: "a",
      content: "Companies",
      key: "companies",
      onClick: () => history.push("/companies"),
    },
    {
      as: "a",
      content: "Tasks",
      key: "tasks",
      onClick: () => history.push("/tasks"),
    },
    {
      as: "a",
      content: "Users",
      key: "users",
      onClick: () => history.push("/users"),
    },
  ];

  const rightItems = [
    {
      as: "a",
      content: "Logout",
      key: "logout",
      onClick: () => logout(),
    },
  ];

  return (
    <Menu inverted id="semantic-menu">
      {/* fixed="top" if sticky */}
      {/* <Menu.Item>
        <Image size="tiny" src={logoLight} />
      </Menu.Item> */}

      {leftItems.map((item) => (
        <Menu.Item {...item} />
      ))}
      <Menu.Menu position="right">
        {rightItems.map((item) => (
          <Menu.Item {...item} />
        ))}
      </Menu.Menu>
    </Menu>
  );
};

export default SemanticNavBar;
