import React from "react";
import { useContext } from "react";
import { UserContext } from "../context-provider/UserProvider";

export const Home = () => {
  const { logout } = useContext(UserContext);
  return (
    <div>
      <h1>Hello, world!</h1>
      <p>Tasks to do should make up the home page.</p>
      <button
        onClick={() => {
          logout();
        }}
      >
        Logout
      </button>
    </div>
  );
};
