const SimpleRequest = ({ request }) => {
  return (
    <div>
      {request.steps.map((x) => (
        <div div> {x.question} </div>
      ))}
    </div>
  );
};
export default SimpleRequest;
