import { Formik } from "formik";
import { Form, Button, Dropdown } from "semantic-ui-react";
import CompanySelect from "../../company-select";
import useFetch from "../../../hooks/use-fetch";
import "./style.scss";
import { USERTYPEOPTIONS } from "../../utils/enumerable";
import { useContext, useState } from "react";
import { UserContext } from "../../../context-provider/UserProvider";
import { useEffect } from "react";
import IconButton from "../../ui/button";

const EditUser = ({ userDetails, setEditing, getUserDetails }) => {
  const api = useFetch();
  const { user } = useContext(UserContext);

  const updateUser = async (values) => {
    await api.fetchData("api/users/update_user", "PUT", values);
    getUserDetails();
    setEditing(false);
  };
  const [options, setOptions] = useState(USERTYPEOPTIONS);

  useEffect(() => {
    if (user.type !== "Admin") {
      //Don't let non admins choose admin
      const newOptions = USERTYPEOPTIONS.filter((x) => x.key !== "Admin");
      setOptions(newOptions);
    }
  }, [user.type]);

  return (
    <Formik
      initialValues={{
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        phone: userDetails.phone,
        companyGuids: userDetails.companies.map((x) => x.guid),
        guid: userDetails.guid,
        type: userDetails.type,
        password: "",
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className="edit-user-form">
          <div>
            <div className="space-between-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <Form.Input
                  label="First Name"
                  value={values.firstName}
                  onChange={(e) => setFieldValue(`firstName`, e.target.value)}
                />
                <Form.Input
                  label="Last Name"
                  value={values.lastName}
                  onChange={(e) => setFieldValue(`lastName`, e.target.value)}
                />
              </div>
              {/* <div className="field">
                <label>Active</label>
                <Checkbox
                  checked={values.active}
                  onChange={(e) => {
                    setFieldValue("active", !values.active);
                  }}
                />
              </div> */}
            </div>
            <Form.Input
              label="Email"
              value={values.email}
              onChange={(e) => setFieldValue(`email`, e.target.value)}
            />
            <Form.Input
              label="Phone Number"
              value={values.phone}
              onChange={(e) => setFieldValue(`phone`, e.target.value)}
              maxLength="10"
            />
            <Form.Input
              label="New Password"
              type="password"
              onChange={(e) => setFieldValue(`password`, e.target.value)}
            />
            <div className="field">
              {/*this just exists to get semantic styling*/}
              <label>Companies Allowed</label>
              <CompanySelect
                setCompanyId={setFieldValue}
                fieldName={`companyGuids`}
                defaultCompany={values.companyGuids}
                multiple
              />
            </div>
            <div className="field">
              {/*this just exists to get semantic styling*/}
              <label>User Type</label>
              <Dropdown
                placeholder="Type"
                fluid
                selection
                style={{ width: 250 }}
                options={options}
                defaultValue={values.type}
                onChange={(e, data) => {
                  setFieldValue("type", data.value);
                }}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <IconButton
                title="Cancel"
                text="Cancel"
                color="taupe"
                onClick={async () => {
                  setEditing(false);
                }}
              />

              <IconButton
                title="Submit"
                text="Submit"
                type="submit"
                onClick={async () => {
                  updateUser(values);
                }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditUser;
