import { Formik } from "formik";
import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import useFetch from "../../../hooks/use-fetch";
import IconButton from "../../ui/button";
import "../style.scss";

const EditOpening = ({ request, getSubscriptionDetails }) => {
  const api = useFetch();
  const [addingOpening, setAddingOpening] = useState(false);
  const initialValues = {
    RequestGuid: request.guid,
    OpeningText: request.opening ?? "",
  };

  const updateRequestOpening = async (payload) => {
    console.log(payload);
    await api.fetchData("api/subscriptions/edit_opening", "PUT", payload);
    setAddingOpening(false);
    getSubscriptionDetails();
  };

  return (
    <div>
      {!addingOpening ? (
        <div>
          <div>
            {request.opening ? (
              <div className="campaign-question-group">
                <div className="space-between-container">
                  <h4>Campaign Opening</h4>
                  <IconButton
                    title="Edit Opening"
                    text="Edit"
                    type="submit"
                    onClick={() => {
                      setAddingOpening(true);
                    }}
                    color="red"
                  />
                </div>
                <div>{request.opening}</div>
              </div>
            ) : (
              <div className="campaign-question-group">
                <div className="space-between-container">
                  <div>This campaign doesn't have an opening message.</div>
                  <IconButton
                    title="Add"
                    text="Add Opening"
                    type="submit"
                    onClick={() => {
                      setAddingOpening(true);
                    }}
                    color="red"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        // Add or edit the opening here
        <div className="campaign-question-group ">
          <Formik initialValues={initialValues}>
            {({ values, setFieldValue, handleBlur }) => (
              <Form>
                <Form.TextArea
                  id="opening"
                  label="Campaign Opening"
                  value={values.OpeningText}
                  placeholder="Message to read before asking questions."
                  onChange={(e) => setFieldValue(`OpeningText`, e.target.value)}
                  onBlur={handleBlur}
                />
                <IconButton
                  title="Save"
                  text="Save Opening"
                  type="submit"
                  onClick={() => {
                    updateRequestOpening(values);
                  }}
                  color="red"
                />
                <IconButton
                  title="Cancel"
                  text="Cancel"
                  onClick={() => {
                    setAddingOpening(false);
                  }}
                  color="taupe"
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};
export default EditOpening;
