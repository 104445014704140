import { Formik } from "formik";
import { Form, Input } from "semantic-ui-react";
import useFetch from "../../../hooks/use-fetch";
import { removeSeconds } from "../../utils/misc";
import "../style.scss";

const EditSubscription = ({
  subscription,
  subscriptionId,
  getSubscriptionDetails,
}) => {
  const api = useFetch();

  const editSubscription = async (payload) => {
    await api.fetchData(
      "api/subscriptions/update_subscription",
      "PUT",
      payload
    );
    getSubscriptionDetails();
  };

  const showButton = (values) => {
    if (
      values.endTime !== subscription.endTime ||
      removeSeconds(values.startTime) !==
        removeSeconds(subscription.startTime) ||
      removeSeconds(values.name) !== removeSeconds(subscription.name)
    ) {
      return (
        <span
          className="clickable"
          onClick={() => {
            editSubscription(values);
          }}
        >
          Save Changes
        </span>
      );
    }
  };

  return (
    <Formik
      initialValues={{
        guid: subscriptionId,
        name: subscription.name,
        startTime: subscription.startTime,
        endTime: subscription.endTime,
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className="subscription-form">
          <div style={{ width: "75%" }}>
            <Form.Input
              key={subscription.guid}
              id="request"
              label="Subscription Name"
              onChange={(e) => setFieldValue(`name`, e.target.value)}
              defaultValue={subscription.name}
            />
            <div>
              <span>Start Time</span>
              <Input
                style={{ width: "100%" }}
                name="startTime"
                // label="Start Time"
                defaultValue={removeSeconds(subscription.startTime)}
                onChange={(e) => {
                  setFieldValue("startTime", e.target.value.concat(":00"));
                }}
                type="time"
              />
              <span>End Time</span>
              <Input
                style={{ width: "100%" }}
                name="endTime"
                // label="End Time"
                defaultValue={removeSeconds(subscription.endTime)}
                onChange={(e) => {
                  setFieldValue("endTime", e.target.value.concat(":00"));
                }}
                type="time"
              />
            </div>
            {showButton(values)}
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default EditSubscription;
