import IconButton from "../../ui/button";
import { formatPhone } from "../../utils/misc";

const CustomerDetails = ({ customer, setEditing }) => {
  return (
    <div>
      <div>
        <h2>{customer.firstName + " " + customer.lastName}</h2>
        <div>{formatPhone(customer.phone)}</div>
        <div>{customer.email}</div>
        <div>{customer.companyName}</div>
      </div>
      <IconButton
        title="Edit"
        text="Edit"
        onClick={async () => {
          setEditing(true);
        }}
      />
    </div>
  );
};

export default CustomerDetails;
