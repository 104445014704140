import useFetch from "../../../hooks/use-fetch";
import { useState } from "react";
import { Button, Form, Input, Loader } from "semantic-ui-react";
import IconButton from "../../ui/button";

const ImportCustomers = ({ companyId }) => {
  const api = useFetch();
  const [file, setFile] = useState({});
  const [isFilePicked, setIsFilePicked] = useState(false);

  const handleSubmit = async () => {
    setIsFilePicked(false);
    let formData = new FormData();
    formData.append("file", file);
    formData.append("companyGuid", companyId);

    await api.fetchData(`api/imports/import_customers`, "POST", formData, {});
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <h2>Import Customers</h2>
      <div
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Input
          type="file"
          name="file"
          icon="file text outline"
          iconPosition="left"
          onChange={(e) => {
            console.log(e.target.files[0]);
            setFile(e.target.files[0]);
            setIsFilePicked(true);
          }}
        />
        {isFilePicked ? (
          <IconButton title="Submit" text="Submit" type="submit" />
        ) : (
          ""
        )}
        {api.isFetching ? (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Loader active />
          </div>
        ) : (
          ""
        )}
      </div>
    </Form>
  );
};

export default ImportCustomers;
