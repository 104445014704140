import { Formik, Form } from "formik";
import { Input, Card } from "semantic-ui-react";
import useFetch from "../../../hooks/use-fetch";
import { useContext, useState } from "react";
import { UserContext } from "../../../context-provider/UserProvider";
import { useHistory } from "react-router";
import "./style.scss";
import IconButton from "../../ui/button";
import * as Yup from "yup";
const CreateSubscriptionForm = () => {
  const api = useFetch();
  const { user, companyId } = useContext(UserContext);
  const history = useHistory();
  const [adding, setAdding] = useState(false);

  const saveSubscription = async (values) => {
    const payload = {
      createdByGuid: user.guid,
      companyGuid: companyId,
      ...values,
    };
    const response = await api.fetchData(
      "api/subscriptions/create_subscription",
      "PUT",
      payload
    );

    history.push(`subscriptions/${response.guid}`);
  };
  // TODO:Form validation, can't submit without a name / if start time > end time
  return (
    <div>
      {!adding ? (
        <div>
          <IconButton
            title="Add"
            text="New Subscription"
            onClick={() => {
              setAdding(true);
            }}
            icon="add"
          />
        </div>
      ) : (
        <Formik
          initialValues={{
            name: "",
            startTime: "8:00:00",
            endTime: "17:00:00",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Subscription must have a name."),
          })}
          onSubmit={async (values) => {
            saveSubscription(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Card fluid>
              <Form>
                <div className="create-subscription-form">
                  <Input
                    label="Name"
                    placeholder="New Subscription"
                    name="name"
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />

                  <Input
                    label="Start Time"
                    name="startTime"
                    defaultValue="08:00"
                    onChange={(e) => {
                      setFieldValue("startTime", e.target.value.concat(":00"));
                    }}
                    type="time"
                  />
                  <Input
                    label="End Time"
                    name="endTime"
                    defaultValue="17:00"
                    onChange={(e) => {
                      setFieldValue("endTime", e.target.value.concat(":00"));
                    }}
                    type="time"
                  />

                  <div style={{ display: "flex" }}>
                    <IconButton
                      color="grey"
                      onClick={() => {
                        setAdding(false);
                      }}
                      icon="cancel"
                    />
                    <IconButton type="submit" icon="save" />
                  </div>
                </div>
              </Form>
            </Card>
          )}
        </Formik>
      )}
    </div>
  );
};

export default CreateSubscriptionForm;
