import { useContext } from "react";
import { Pagination } from "semantic-ui-react";
import { TableContext } from "../../context-provider/TableProvider";

const Paginate = ({ totalRecords = 10 }) => {
  const { activePage, setActivePage, recordsPerPage } =
    useContext(TableContext);

  if (Math.ceil(totalRecords / recordsPerPage) < 2) {
    return "";
  }
  return (
    <Pagination
      defaultActivePage={activePage}
      totalPages={Math.ceil(totalRecords / recordsPerPage)}
      onPageChange={(e, data) => setActivePage(data.activePage)}
    />
  );
};
export default Paginate;
