import { Formik } from "formik";
import { Loader, Form, Radio, Button, Divider } from "semantic-ui-react";
import useFetch from "../../hooks/use-fetch";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
// import "./style.scss";
import * as Yup from "yup";
import { decodeToken } from "../../utils/jwt";
import IconButton from "../ui/button";

const CustomerQuestionnaires = () => {
  const api = useFetch();
  const history = useHistory();

  const { token } = useParams();
  const decoded = decodeToken(token);
  const [questionnaire, setQuestionnaire] = useState("");
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const fetchQuestions = async (decoded) => {
    const request = await api.fetchData(
      "api/ivr/get_questionnaire",
      "POST",
      {
        requestGuid: decoded.requestGuid,
        customerGuid: decoded.customerGuid,
      },
      { "Content-Type": "application/json" },
      token
    );
    if (request === true) {
      setAlreadyAnswered(true);
      return;
    }
    setQuestionnaire(request);
  };

  const submitQuestionnaire = async (values) => {
    setSubmitting(true);
    await api.fetchData(
      "api/ivr/submit_questionnaire",
      "POST",
      values,
      { "Content-Type": "application/json" },
      token
    );

    history.push("/thanks");
  };
  useEffect(() => {
    if (decoded.requestGuid) {
      fetchQuestions(decoded);
    }
  }, []);

  return (
    <div className="questionnaire-container">
      {alreadyAnswered ? (
        <div>You have already answered this questionnaire</div>
      ) : !questionnaire ? (
        <Loader active />
      ) : (
        <Formik
          initialValues={{
            requestGuid: decoded.requestGuid,
            customerGuid: decoded.customerGuid,
            questions: questionnaire.questions.map((x) => ({
              questionGuid: x.guid,
              answerGuid: "",
            })),
          }}
          onSubmit={(values) => {
            submitQuestionnaire(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <h2>{questionnaire.requestName}</h2>
              {questionnaire.questions.map((question, iQ) => {
                return (
                  <div
                    key={question.guid + "-" + iQ}
                    className="questionnaire-question-answer"
                  >
                    <h3>{iQ + 1 + ": " + question.text}</h3>
                    <div className="questionnaire-radio-container">
                      {question.answers.map((answer, iA) => {
                        return (
                          <Form.Field key={answer.guid + "-" + iA}>
                            <Radio
                              label={answer.text}
                              name={answer.text}
                              value={answer.guid}
                              checked={
                                values.questions[iQ].answerGuid === answer.guid
                              }
                              onChange={(e, { value }) =>
                                setFieldValue(
                                  `questions.${[iQ]}.answerGuid`,
                                  value
                                )
                              }
                            />
                          </Form.Field>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              <IconButton
                title="Submit"
                text="Submit"
                onClick={() => {
                  submitQuestionnaire(values);
                }}
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default CustomerQuestionnaires;
