import { useState } from "react";
import { useParams } from "react-router";
import { Card } from "semantic-ui-react";
import RequestDetails from "./request-details";
import EditRequestDetails from "./edit-request";
import CreateCampaignModal from "./create-request-modal";
import "../style.scss";
import IconButton from "../../ui/button";
import CreateQuestionModal from "./create-question-modal";
import SimpleRequest from "./simple-request";

const RequestCards = ({
  subscription,
  getSubscriptionDetails,
  tasks,
  customers,
}) => {
  const { subscriptionId } = useParams();
  const [detailedView, setDetailedView] = useState(null);

  return (
    <div>
      {subscription.requests.map((request, i) => (
        <Card key={request.guid} fluid className="campaign-card">
          <div className="space-between-container">
            <h2>
              {request.name} - {request.type}
            </h2>
            <div style={{ display: "flex" }}>
              {detailedView === null ? (
                <IconButton
                  onClick={() => setDetailedView(i)}
                  title="Details"
                  icon="angle down"
                />
              ) : (
                <IconButton
                  onClick={() => setDetailedView(null)}
                  title="Details"
                  icon="angle up"
                />
              )}
            </div>
          </div>
          {detailedView === i ? (
            <RequestDetails
              key={request.guid}
              request={request}
              i={i}
              customers={customers}
              tasks={tasks}
              getSubscriptionDetails={getSubscriptionDetails}
            />
          ) : (
            <SimpleRequest
              key={request.guid}
              request={request}
              tasks={tasks}
              subscriptionId={subscriptionId}
              getSubscriptionDetails={getSubscriptionDetails}
            />

            // <EditRequestDetails
            //   key={request.guid}
            //   request={request}
            //   setEditing={setEditing}
            //   tasks={tasks}
            //   subscriptionId={subscriptionId}
            //   getSubscriptionDetails={getSubscriptionDetails}
            // />
          )}
        </Card>
      ))}
      <CreateCampaignModal
        subscriptionId={subscriptionId}
        getSubscriptionDetails={getSubscriptionDetails}
      />
    </div>
  );
};

export default RequestCards;
