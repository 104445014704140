import EditQuestionView from "../components/subscriptions/edit-question";
import Subscriptions from "../components/subscriptions/index";
import SubscriptionDetails from "../components/subscriptions/subscription-details";

const SubscriptionRoutes = [
  {
    path: "/subscriptions",
    component: Subscriptions,
    exact: true,
  },
  {
    path: "/subscriptions/:subscriptionId",
    component: SubscriptionDetails,
    exact: true,
  },
  {
    path: "/subscriptions/questions/:questionId",
    component: EditQuestionView,
    exact: true,
  },
];

export default SubscriptionRoutes;
