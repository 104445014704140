import { Formik } from "formik";
import { Loader, Form, Radio, Button } from "semantic-ui-react";
import useFetch from "../../hooks/use-fetch";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import "./style.scss";
import IconButton from "../ui/button";

const ThankYouView = () => {
  return (
    <div>
      <IconButton
        title="Submit"
        text="Submit"
        onClick={() => {
          window.open("about:blank", "_self");
          window.close();
        }}
      />
      Thank You for your responses! You can close this tab now.
    </div>
  );
};

export default ThankYouView;
