import { useEffect, useState } from "react";
import useFetch from "../../hooks/use-fetch";
import SubscriptionsTable from "./subscription-table";
import { useContext } from "react";
import { UserContext } from "../../context-provider/UserProvider";
import CompanySelect from "../company-select";
import CreateSubscriptionForm from "./create-subscriptions";
import TableProvider from "../../context-provider/TableProvider";

const SubscriptionsView = () => {
  const { companyId, setCompanyId } = useContext(UserContext);

  const api = useFetch({
    subscriptions: [
      {
        guid: "",
        name: "",
        createdDate: "",
        startTime: "",
        endTime: "",
        createdById: "",
        IsActive: false,
      },
    ],
  });

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1em 0em",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <h2 style={{ marginRight: "15px" }}>Subscriptions</h2>
          <CompanySelect setCompanyId={setCompanyId} />
        </div>
        <CreateSubscriptionForm />
      </div>
      <TableProvider>
        <SubscriptionsTable api={api} companyId={companyId} />
      </TableProvider>
    </div>
  );
};

export default SubscriptionsView;
