import jwt_decode from "jwt-decode";

export const formatTimeSpan = (hoursMinutesSeconds) => {
  // Makes 13:05:04 --> 1:05PM
  const split = hoursMinutesSeconds.split(":");
  let hour;
  let amOrPm;
  if (parseInt(split[0]) < 13) {
    hour = parseInt(split[0]) === 0 ? "12" : split[0];
    amOrPm = parseInt(split[0]) === 12 ? "PM" : "AM";
  } else {
    hour = parseInt(split[0]) - 12;
    amOrPm = "PM";
  }
  return hour + ":" + split[1] + " " + amOrPm;
};

export const removeSeconds = (hoursMinutesSeconds) => {
  // Makes 13:05:04 --> 13:05
  const split = hoursMinutesSeconds.split(":");
  return split[0] + ":" + split[1];
};

export const formatPhone = (n) => {
  let nonNumbersExist = /\D/g;
  if (n != null) {
    n = n.toString();
    if (n.length === 10 && nonNumbersExist.test(n) === false) {
      return `(${n.slice(0, 3)}) ${n.slice(3, 6)}-${n.slice(6, 10)}`;
    } else if (n.length === 11 && nonNumbersExist.test(n) === false) {
      n = n.slice(-10);
      return `(${n.slice(0, 3)}) ${n.slice(3, 6)}-${n.slice(6, 10)}`;
    } else if (
      n.length === 12 &&
      nonNumbersExist.test(n.slice(-10)) === false
    ) {
      n = n.slice(-10);
      return `(${n.slice(0, 3)}) ${n.slice(3, 6)}-${n.slice(6, 10)}`;
    } else {
      return n;
    }
  } else {
    return "";
  }
};

export const formatAddress = ({ street_address, city, state, zip }) =>
  `${street_address}, ${city}, ${state}, ${zip}`;

export const decodeJwt = (tokenString) => {
  try {
    return jwt_decode(tokenString);
  } catch (e) {
    return {};
  }
};
