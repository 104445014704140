import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./routes/protected-route";
import { Layout } from "./components/Layout";
import LoginView from "./components/login-pages";
import UserProvider from "./context-provider/UserProvider";
import "./custom.css";
import { Home } from "./components/Home";
import AppRoutes from "./routes";
import QuestionnaireRoutes from "./routes/questionnaires";

function App() {
  return (
    <UserProvider>
      <Switch>
        <Route exact path="/login" component={LoginView} />
        {QuestionnaireRoutes.map((route) => {
          return (
            <Route
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          );
        })}

        <ProtectedRoute path="/" redirectPath="/login">
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              {AppRoutes.map((route) => {
                return (
                  <Route
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                  />
                );
              })}
            </Switch>
          </Layout>
        </ProtectedRoute>
      </Switch>
    </UserProvider>
  );
}

export default App;
