import Customers from "../components/customers/index";
import CustomerView from "../components/customers/customer-details";
import CustomerResponses from "../components/customers/customer-responses/index";
import AddCustomerForm from "../components/customers/add-customer-form";

const CustomerRoutes = [
  {
    path: "/customers",
    component: Customers,
    exact: true,
  },
  {
    path: "/customers/add",
    component: AddCustomerForm,
    exact: true,
  },
  {
    path: "/customers/:customerId/:campaignId",
    component: CustomerResponses,
    exact: true,
  },
  {
    path: "/customers/:customerId",
    component: CustomerView,
    exact: true,
  },
];

export default CustomerRoutes;
