import Tasks from "../components/tasks/index";
// import TaskDetails from "../components/tasks/task-details";

const TaskRoutes = [
  {
    path: "/Tasks",
    component: Tasks,
    exact: true,
  },
  // {
  //   path: "/Tasks/:TaskId",
  //   component: TaskDetails,
  //   exact: true,
  // },
];

export default TaskRoutes;
