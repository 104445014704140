import { Formik, Form } from "formik";
import { Dropdown } from "semantic-ui-react";
import useFetch from "../../../hooks/use-fetch";
import "../style.scss";
import IconButton from "../../ui/button";
import * as Yup from "yup";

const TestIVRCampaign = ({ requestGuid, customers }) => {
  const api = useFetch();

  const startIvr = ({ customerGuid }) => {
    const payload = {
      customerGuid,
      requestGuid,
    };
    api.fetchData("api/ivr/test_request", "POST", payload);
  };

  return (
    <Formik
      initialValues={{
        customerGuid: "",
      }}
      validationSchema={Yup.object().shape({
        customerGuid: Yup.string().required("Must have a customer."),
      })}
      onSubmit={(values) => {
        startIvr(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className="test-ivr-form">
          <div className="customer-dropdown">
            <Dropdown
              fluid
              clearable
              selection
              search
              selectOnBlur={false}
              options={customers.map((x) => {
                return {
                  key: x.guid,
                  value: x.guid,
                  text:
                    x.firstName +
                    " " +
                    x.lastName +
                    " - " +
                    x.email +
                    " - " +
                    x.phone,
                };
              })}
              onChange={(e, data) => {
                setFieldValue("customerGuid", data.value);
              }}
            />
          </div>
          <IconButton type="submit" icon="phone" text="Test Campaign" />
        </Form>
      )}
    </Formik>
  );
};

export default TestIVRCampaign;
