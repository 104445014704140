import useFetch from "../../../hooks/use-fetch";
import { Form, Dropdown } from "semantic-ui-react";
import { Formik } from "formik";
import "./style.scss";
import { useHistory } from "react-router-dom";
import CompanySelect from "../../company-select";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context-provider/UserProvider";
import { USERTYPEOPTIONS } from "../../utils/enumerable";
import IconButton from "../../ui/button";

const AddUserForm = () => {
  const history = useHistory();
  const api = useFetch();
  const { user } = useContext(UserContext);
  const saveUser = async (values) => {
    const response = await api.fetchData(
      "api/users/create_user",
      "PUT",
      values
    );
    history.push(`${response.guid}`);
  };

  const [options, setOptions] = useState(USERTYPEOPTIONS);

  useEffect(() => {
    if (user.type !== "Admin") {
      //Don't let non admins choose admin
      const newOptions = USERTYPEOPTIONS.filter((x) => x.key !== "Admin");
      setOptions(newOptions);
    }
  }, [user.type]);

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          companyGuids: [],
          userGuid: "",
          type: "",
          password: "",
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="add-user-form">
            <div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Form.Input
                  label="First Name"
                  onChange={(e) => setFieldValue(`firstName`, e.target.value)}
                />
                <Form.Input
                  label="Last Name"
                  onChange={(e) => setFieldValue(`lastName`, e.target.value)}
                />
              </div>
              <Form.Input
                label="Email"
                onChange={(e) => setFieldValue(`email`, e.target.value)}
              />
              <Form.Input
                label="Phone Number"
                onChange={(e) => setFieldValue(`phone`, e.target.value)}
                maxLength="10"
              />
              <Form.Input
                label="Password"
                type="password"
                onChange={(e) => setFieldValue(`password`, e.target.value)}
              />
              <div className="field">
                {/*this just exists to get semantic styling*/}
                <label>Companies Allowed</label>
                <CompanySelect
                  setCompanyId={setFieldValue}
                  defaultCompany={" "}
                  fieldName={`companyGuids`}
                  multiple
                />
              </div>
              <div className="field">
                {/*this just exists to get semantic styling*/}
                <label>User Type</label>
                <Dropdown
                  placeholder="Type"
                  fluid
                  selection
                  style={{ width: 250 }}
                  options={options}
                  defaultValue={values.type}
                  onChange={(e, data) => {
                    setFieldValue("type", data.value);
                  }}
                />
              </div>
              <IconButton
                title="Submit"
                text="Submit"
                type="submit"
                onClick={async () => {
                  saveUser(values);
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddUserForm;
