import SubscriptionRoutes from "./subscriptions";
import CustomerRoutes from "./customers";
import CompanyRoutes from "./companies";
import UserRoutes from "./users";
import TaskRoutes from "./tasks";
// import { path } from "ramda";

const AppRoutes = [
  ...SubscriptionRoutes,
  ...CustomerRoutes,
  ...CompanyRoutes,
  ...TaskRoutes,
  ...UserRoutes,
];

export default AppRoutes;
