import React, { useState } from "react";
import { Card } from "semantic-ui-react";
import IconButton from "./button";

const ConfirmDeleteButtonGroup = ({
  confirmTitle = "Delete",
  confirmMessage = "Are you sure you want to delete?",
  confirmAction,
}) => {
  const [confirming, setConfirming] = useState(false);

  return (
    <div
      style={{
        width: "150px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      {!confirming ? (
        <IconButton
          title={confirmTitle}
          text={confirmTitle}
          onClick={() => {
            setConfirming(true);
          }}
        />
      ) : (
        <div>
          <div>{confirmMessage}</div>
          <div className="space-between-container">
            <IconButton
              title="No"
              text="No"
              color="taupe"
              onClick={() => {
                setConfirming(false);
              }}
            />
            <IconButton
              title="Confirm"
              text="Yes"
              onClick={() => {
                confirmAction
                  ? confirmAction()
                  : console.log("Did not pass a function");
                setConfirming(false);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmDeleteButtonGroup;
