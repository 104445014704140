import { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import useFetch from "../../hooks/use-fetch";
import TasksTable from "./task-table";
import { useContext } from "react";
import { UserContext } from "../../context-provider/UserProvider";
import CompanySelect from "../company-select";
import TableProvider from "../../context-provider/TableProvider";

const TasksView = () => {
  const { companyId, setCompanyId } = useContext(UserContext);
  const api = useFetch({
    tasks: [
      {
        customerGuid: "",
        firstName: "",
        lastName: "",
        requestGuid: "",
        requestname: "",
        resolved: null,
        resopnseDate: "",
        tasksCreated: [],
      },
    ],
  });

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1em 0em",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <h2 style={{ marginRight: "15px" }}>Tasks</h2>
          <CompanySelect setCompanyId={setCompanyId} />
        </div>
      </div>

      <TableProvider>
        <TasksTable api={api} companyId={companyId} />
      </TableProvider>
    </div>
  );
};

export default TasksView;
