import React from "react";
// This component exists just to get semantic ui style buttons but also be able to easily change styling
// Should have access to semantic ui icons
// https://react.semantic-ui.com/elements/icon/

//Colors primary secondary grey red, blue, green
const IconButton = ({
  onClick,
  color = "red",
  icon,
  text,
  title,
  type,
  fluid = false,
  disabled = false,
}) => {
  return (
    <button
      type={type}
      title={title}
      style={{
        display: "inline-flex",
        justifyContent: "space-around",
      }}
      disabled={disabled}
      onClick={onClick ? () => onClick() : console.log()} // Don't know what to do if you dont pass an onclick
      className={`btn ${color} ${fluid ? "fluid" : ""} ${
        disabled ? "disabled" : ""
      } default-button`}
    >
      {text && <div>{text}</div>}
      {icon && <i className={`${icon} icon button-text `}></i>}
    </button>
  );
};

export default IconButton;
