import { Formik } from "formik";
import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import useFetch from "../../../hooks/use-fetch";
import IconButton from "../../ui/button";
import "../style.scss";

const EditCallbackNumber = ({ request, getSubscriptionDetails }) => {
  const api = useFetch();
  //   const [addingEnding, setAddingEnding] = useState(false);
  const initialValues = {
    RequestGuid: request.guid,
    CallbackNumber: request.callbackNumber ? request.callbackNumber : "",
  };

  const updateRequestPhone = async (payload) => {
    await api.fetchData("api/subscriptions/edit_callback", "PUT", payload);
    getSubscriptionDetails();
  };

  return (
    <div className="campaign-question-group">
      <Formik initialValues={initialValues}>
        {({ values, setFieldValue, handleBlur }) => (
          <Form>
            <Form.Input
              id="Callback"
              label="Callback Number"
              value={values.CallbackNumber}
              //   placeholder="Message to read after questions have been answered."
              maxLength="12"
              onChange={(e) => setFieldValue(`CallbackNumber`, e.target.value)}
              onBlur={handleBlur}
            />
            {JSON.stringify(values) !== JSON.stringify(initialValues) && (
              <div>
                <IconButton
                  title="Save"
                  text="Save"
                  type="submit"
                  onClick={() => {
                    updateRequestPhone(values);
                  }}
                  color="red"
                />
                <IconButton
                  title="Cancel"
                  text="Cancel"
                  onClick={() => {
                    setFieldValue(`CallbackNumber`, request.callbackNumber);
                  }}
                  color="taupe"
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default EditCallbackNumber;
