import { Formik } from "formik";
import { Form, Button, Checkbox } from "semantic-ui-react";
import CompanySelect from "../../company-select";
import useFetch from "../../../hooks/use-fetch";
import "./style.scss";
import IconButton from "../../ui/button";

const EditCustomer = ({ customer, setEditing, getCustomerDetails }) => {
  const api = useFetch();

  const updateCustomer = async (values) => {
    await api.fetchData("api/customers/update_customer", "PUT", values);
    getCustomerDetails();
    setEditing(false);
  };

  return (
    <Formik
      initialValues={{
        active: customer.active,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phoneNumber: customer.phone,
        companyGuid: customer.companyGuid,
        customerGuid: customer.guid,
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className="edit-customer-form">
          <div>
            <div className="space-between-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <Form.Input
                  label="First Name"
                  value={values.firstName}
                  onChange={(e) => setFieldValue(`firstName`, e.target.value)}
                />
                <Form.Input
                  label="Last Name"
                  value={values.lastName}
                  onChange={(e) => setFieldValue(`lastName`, e.target.value)}
                />
              </div>
              <div className="field">
                <label>Active</label>
                <Checkbox
                  checked={values.active}
                  onChange={(e) => {
                    setFieldValue("active", !values.active);
                  }}
                />
              </div>
            </div>
            <Form.Input
              label="Email"
              value={values.email}
              onChange={(e) => setFieldValue(`email`, e.target.value)}
            />
            <Form.Input
              label="Phone Number"
              value={values.phoneNumber}
              onChange={(e) => setFieldValue(`phoneNumber`, e.target.value)}
              maxLength="10"
            />
            <div className="field">
              {/*this just exists to get semantic styling*/}
              <label>Company</label>
              <CompanySelect
                setCompanyId={setFieldValue}
                fieldName={`companyGuid`}
                defaultCompany={values.companyGuid}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <IconButton
                color="taupe"
                title="Cancel"
                text="Cancel"
                onClick={async () => {
                  setEditing(false);
                }}
              />
              <IconButton
                title="Submit"
                text="Submit"
                onClick={async () => {
                  updateCustomer(values);
                }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditCustomer;
