import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { useState } from "react";
import useFetch from "../../../hooks/use-fetch";
import IconButton from "../../ui/button";
import ImportCustomersCampaign from "./import-customers-campaign";
// import ImportCustomers from "../../companies/company-details/import-customers";

const AssignPatientsModal = ({
  subscriptionId,
  getSubscriptionDetails,
  getPossibleCustomers,
}) => {
  const api = useFetch();
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const reload = () => {
    getSubscriptionDetails();
    getPossibleCustomers();
    setSubmitting(false);
    setOpen(false);
  };

  const assignPatients = async (subscriptionId) => {
    setSubmitting(true);
    await api.fetchData(
      `api/subscriptions/assign_all_patients`,
      "POST",
      subscriptionId
    );
    reload();
  };
  const unassignPatients = async (subscriptionId) => {
    setSubmitting(true);
    await api.fetchData(
      `api/subscriptions/unassign_all_patients`,
      "POST",
      subscriptionId
    );
    reload();
  };
  // TODO: Going to want a more robust system for adding customers to subscriptions
  // One idea is to give subscriptions a flag for: when a customer is added to db, always give them this subscription too
  // And then have a bulk select tool for others
  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <IconButton
          title="Assign Patients"
          text="Assign Patients"
          color="taupe"
        />
      }
    >
      <Modal.Header>Assign Patients</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Button
            disabled={submitting}
            onClick={() => {
              assignPatients(subscriptionId);
            }}
          >
            Assign All Patients
          </Button>
          <Button
            disabled={submitting}
            onClick={() => {
              unassignPatients(subscriptionId);
            }}
          >
            Unassign All Patients
          </Button>

          <ImportCustomersCampaign
            subscriptionId={subscriptionId}
            reload={reload}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default AssignPatientsModal;
