import { useContext, useState, useCallback } from "react";
import { UserContext } from "../context-provider/UserProvider";
import { HOST } from "../environment";

const useFetch = (initialValues = {}, onError) => {
  const { user, logout } = useContext(UserContext);
  const [isFetching, setIsFetching] = useState();
  const [data, setData] = useState(initialValues);

  const fetchData = useCallback(
    async (
      route,
      method = "GET",
      params,
      headers = { "Content-Type": "application/json" },
      questionnaireToken = ""
    ) => {
      try {
        setIsFetching(true);
        const response = await fetch(`${HOST}/${route}`, {
          method,
          body:
            method === "GET"
              ? undefined
              : params instanceof FormData
              ? params
              : JSON.stringify(params),
          headers: {
            Authorization: `Bearer ${user.token || questionnaireToken}`,
            ...headers,
          },
        });
        if (!response.ok) {
          if (response.status === 401) {
            logout();
          }
          if (typeof onError === "function") {
            onError(response);
          }
          throw new Error("Fetch failed.");
        }

        const result = await response.json();
        setData(result);

        return result;
      } catch (ex) {
        if (typeof onError === "function") {
          onError(ex);
        }
      } finally {
        setIsFetching(false);
      }
    },
    [user.token]
  );

  return { fetchData, isFetching, data };
};

export default useFetch;
