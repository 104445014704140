import useFetch from "../../../hooks/use-fetch";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, Loader } from "semantic-ui-react";
import CustomerCampaignList from "./campaign-list";
import CustomerDetails from "./customer-details";
import EditCustomer from "./edit-customer";

const CustomerView = () => {
  const apiCustomers = useFetch();
  const apiCampaigns = useFetch();

  const [editing, setEditing] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const { customerId } = useParams();

  const getCustomerDetails = async () => {
    await apiCustomers.fetchData(
      `api/customers/customer_details/${customerId}`
    );
    await apiCampaigns.fetchData(
      `api/customers/customer_campaigns/${customerId}`
    );
    setIsFetching(false);
  };

  useEffect(() => {
    getCustomerDetails();
  }, []);

  return (
    <>
      {!isFetching ? (
        <div style={{ width: "100%" }}>
          <Card fluid style={{ padding: "10px", width: "50%" }}>
            {editing ? (
              <EditCustomer
                customer={apiCustomers.data}
                setEditing={setEditing}
                getCustomerDetails={getCustomerDetails}
              />
            ) : (
              <CustomerDetails
                customer={apiCustomers.data}
                setEditing={setEditing}
              />
            )}
          </Card>
          {apiCampaigns.data.length > 0 && (
            <CustomerCampaignList campaigns={apiCampaigns.data} />
          )}
        </div>
      ) : (
        <Loader active />
      )}
    </>
  );
};

export default CustomerView;
