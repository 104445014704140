import { Card, Button } from "semantic-ui-react";
import useFetch from "../../../../hooks/use-fetch";
import { useEffect, useState } from "react";
import EditOutreachCycle from "./edit-cycle";
import "./style.scss";
import IconButton from "../../../ui/button";

const OutreachCycle = ({ subscriptionId, campaigns }) => {
  const api = useFetch();
  const [editing, setEditing] = useState(false);

  const getOutreachCycle = async (companyId) => {
    api.fetchData("api/subscriptions/outreach_cycle", "POST", companyId);
  };

  useEffect(() => {
    getOutreachCycle(subscriptionId);
  }, [subscriptionId]);

  // Make a fetch call to display current outreach cycle for this subscription
  return (
    <Card fluid className="outreach-card">
      {!editing ? (
        <div>
          <h3>Outreach Cycle</h3>
          {!api.isFetching ? (
            <div>
              {api.data.length > 0 ? (
                <ol>
                  {api.data.map((x) => {
                    return <li key={x.cycleCount}> {x.campaignName} </li>;
                  })}
                </ol>
              ) : (
                "No outreach cycle set."
              )}
            </div>
          ) : (
            ""
          )}
          <IconButton
            color="taupe"
            title="Edit"
            text="Edit"
            fluid
            onClick={() => {
              setEditing(true);
            }}
          />
        </div>
      ) : (
        <EditOutreachCycle
          outreachCycles={api.data}
          setEditing={setEditing}
          campaigns={campaigns}
          subscriptionId={subscriptionId}
          getOutreachCycle={getOutreachCycle}
        />
      )}
    </Card>
  );
};

export default OutreachCycle;
