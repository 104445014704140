import useFetch from "../../../hooks/use-fetch";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, Loader } from "semantic-ui-react";
import CompanyDetails from "./company-details";
import EditCompany from "./edit-company";
import ImportCustomers from "./import-customers";

const CompanyView = () => {
  const api = useFetch();

  const [editing, setEditing] = useState(false);
  const { companyId } = useParams();

  const getCompanyDetails = () => {
    api.fetchData(`api/companies/company_details/${companyId}`);
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  return (
    <>
      {!api.isFetching ? (
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <Card fluid style={{ padding: "10px", width: "50%" }}>
            {editing ? (
              <EditCompany
                company={api.data}
                setEditing={setEditing}
                getCompanyDetails={getCompanyDetails}
              />
            ) : (
              <CompanyDetails company={api.data} setEditing={setEditing} />
            )}
          </Card>
          <ImportCustomers companyId={companyId} />
        </div>
      ) : (
        <Loader active />
      )}
    </>
  );
};

export default CompanyView;
