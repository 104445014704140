import React, { Component } from "react";
import SemanticNavBar from "./SemanticNavMenu";
import "./style.scss";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className="layout">
        <SemanticNavBar />
        <div style={{ display: "flex", flex: 1, margin: "25px" }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
