import React from "react";
import { Form, Modal, Dropdown } from "semantic-ui-react";
import { Formik } from "formik";
import { REQUESTTYPEOPTIONS } from "../../utils/enumerable";
import useFetch from "../../../hooks/use-fetch";
import IconButton from "../../ui/button";

const CreateCampaignModal = ({ subscriptionId, getSubscriptionDetails }) => {
  const api = useFetch();
  const [open, setOpen] = React.useState(false);

  const initialState = {
    subscriptionnId: subscriptionId,
    requestName: "New Campaign",
    requestType: "Email",
  };

  const saveNewRequest = async (values) => {
    await api.fetchData(`api/subscriptions/new_request`, "POST", values);
    getSubscriptionDetails();
  };

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeOnDimmerClick={false}
      open={open}
      trigger={
        <IconButton title="New Campaign" text="New Campaign" color="taupe" />
      }
    >
      <Modal.Header>New Campaign</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Formik initialValues={initialState}>
            {({ values, setFieldValue, handleBlur }) => (
              <Form className="subscription-form">
                <Form.Input
                  id="request"
                  label="Campaign Name"
                  placeholder="New Campaign"
                  onChange={(e) => setFieldValue(`requestName`, e.target.value)}
                  onBlur={handleBlur}
                  width={6}
                />
                <label for="thing">Contact Type</label>
                <Dropdown
                  placeholder="Type of campaign"
                  fluid
                  selection
                  style={{ width: 250 }}
                  options={REQUESTTYPEOPTIONS}
                  defaultValue={values.requestType}
                  onChange={(e, data) => {
                    setFieldValue("requestType", data.value);
                  }}
                />
                <Modal.Actions>
                  <IconButton
                    title="Cancel"
                    text="Cancel"
                    onClick={async () => {
                      setOpen(false);
                    }}
                    color="taupe"
                  />
                  <IconButton
                    title="Submit"
                    text="Submit"
                    onClick={() => {
                      saveNewRequest(values);
                      setOpen(false);
                    }}
                    icon="checkmark"
                  />
                </Modal.Actions>
              </Form>
            )}
          </Formik>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default CreateCampaignModal;
