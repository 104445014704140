import { Button } from "semantic-ui-react";
import IconButton from "../../ui/button";
import { formatPhone } from "../../utils/misc";

const UserDetails = ({ userDetails, setEditing }) => {
  return (
    <div>
      <div>
        <h2>{userDetails.firstName + " " + userDetails.lastName}</h2>
        <div>Phone: {formatPhone(userDetails.phone)}</div>
        <div>Email: {userDetails.email}</div>
        <div>Type: {userDetails.type}</div>
      </div>

      <IconButton
        title="Edit"
        text="Edit"
        onClick={() => {
          setEditing(true);
        }}
      />
    </div>
  );
};

export default UserDetails;
