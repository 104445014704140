import useFetch from "../../../hooks/use-fetch";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, Loader } from "semantic-ui-react";
import UserDetails from "./user-details";
import EditUser from "./edit-user";

const UserView = () => {
  const api = useFetch();

  const [editing, setEditing] = useState(false);
  const { userId } = useParams();

  const getUserDetails = async () => {
    api.fetchData(`api/users/user_details/${userId}`);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      {!api.isFetching ? (
        <div style={{ width: "100%" }}>
          <Card fluid style={{ padding: "10px", width: "50%" }}>
            {editing ? (
              <EditUser
                userDetails={api.data}
                setEditing={setEditing}
                getUserDetails={getUserDetails}
              />
            ) : (
              <UserDetails userDetails={api.data} setEditing={setEditing} />
            )}
          </Card>
        </div>
      ) : (
        <Loader active />
      )}
    </>
  );
};

export default UserView;
