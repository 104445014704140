import { Button, Label } from "semantic-ui-react";
import { formatPhone } from "../../utils/misc";
import IconButton from "../../ui/button";

const CompanyDetails = ({ company, setEditing }) => {
  return (
    <div style={{ width: "100%" }}>
      <div className="space-between-container">
        <h2>{company.companyName}</h2>
        <Label color={company.active ? "green" : "red"}>
          {company.active ? "Active" : "Inactive"}
        </Label>
      </div>
      <div>Account #: {company.accountNumber}</div>
      <div>Twilio Number: {formatPhone(company.twilioPhone)}</div>
      <div>Company Transfer Numebr: {formatPhone(company.transferPhone)}</div>
      <div>
        Address: {company.streetOne}, {company.city}, {company.state}{" "}
        {company.zip}
      </div>

      <IconButton
        title="Edit"
        text="Edit"
        onClick={async () => {
          setEditing(true);
        }}
      />
    </div>
  );
};

export default CompanyDetails;
