import Users from "../components/users/index";
import UserView from "../components/users/user-details";
import AddUserForm from "../components/users/add-user-form";

const UserRoutes = [
  {
    path: "/users",
    component: Users,
    exact: true,
  },
  {
    path: "/users/add",
    component: AddUserForm,
    exact: true,
  },
  {
    path: "/users/:userId",
    component: UserView,
    exact: true,
  },
];

export default UserRoutes;
