import React from "react";
import { Table, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatPhone } from "../utils/misc";
import { useContext } from "react";
import { TableContext } from "../../context-provider/TableProvider";
import { useEffect } from "react";
import Paginate from "../ui/pagination";

const CustomersTable = ({ api, companyId }) => {
  const { activePage, recordsPerPage } = useContext(TableContext);

  useEffect(() => {
    if (companyId) {
      api.fetchData("api/customers/company_customers", "POST", {
        companyGuid: companyId,
        recordsPerPage,
        activePage,
      });
    }
  }, [companyId, activePage]);

  return (
    <div>
      <Paginate totalRecords={api.data.total} />
      <Table celled padded>
        <Table.Header>
          <Table.Row className="semantic-header-row">
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {!api.isFetching ? (
          <Table.Body>
            {api.data.customers.map((x) => {
              return (
                <Table.Row key={x.guid}>
                  <Table.Cell>
                    <Link className="text-dark" to={`/customers/${x.guid}`}>
                      {x.firstName + " " + x.lastName}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{x.email}</Table.Cell>
                  <Table.Cell>{formatPhone(x.phone)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        ) : (
          <Loader active />
        )}
      </Table>
    </div>
  );
};
export default CustomersTable;
