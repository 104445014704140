import useFetch from "../../../hooks/use-fetch";
import { Button, Form, Dropdown } from "semantic-ui-react";
import { Formik } from "formik";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { STATES } from "../../utils/enumerable";
import IconButton from "../../ui/button";

const AddCompanyForm = () => {
  const history = useHistory();
  const api = useFetch();

  const saveCompany = async (values) => {
    const response = await api.fetchData(
      "api/companies/create_company",
      "PUT",
      values
    );
    history.push(`${response.guid}`);
  };

  return (
    <>
      <Formik
        initialValues={{
          active: true,
          name: "",
          accountNumber: "",
          city: "",
          phoneNumber: "",
          zip: "",
          state: "",
          street1: "",
          street2: "",
          taxId: "",
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="add-company-form">
            <div>
              <Form.Input
                label="Company Name"
                onChange={(e) => setFieldValue(`name`, e.target.value)}
              />
              <Form.Input
                label="Account Number"
                onChange={(e) => setFieldValue(`accountNumber`, e.target.value)}
              />
              <Form.Input
                label="Twilio Number"
                onChange={(e) => setFieldValue(`twilioPhone`, e.target.value)}
                maxLength="12"
              />
              <Form.Input
                label="Company Transfer Number"
                onChange={(e) => setFieldValue(`transferPhone`, e.target.value)}
                maxLength="10"
              />
              <Form.Input
                label="Tax ID"
                onChange={(e) => setFieldValue(`taxId`, e.target.value)}
                maxLength="11"
              />
              <div className="space-between-container">
                <div className="field">
                  {/*this just exists to get semantic styling*/}
                  <label>State</label>
                  <Dropdown
                    fluid
                    search
                    selection
                    // defaultValue="AL"
                    value={values.state}
                    options={STATES.map((x) => {
                      return {
                        key: x.text,
                        value: x.value,
                        text: x.text,
                      };
                    })}
                    onChange={(e, data) => {
                      //If using setFieldValue with formik, pass the fieldName too
                      setFieldValue("state", data.value);
                    }}
                  />
                </div>
                <Form.Input
                  label="City"
                  onChange={(e) => setFieldValue(`city`, e.target.value)}
                />
                <Form.Input
                  label="Zip"
                  onChange={(e) => setFieldValue(`zip`, e.target.value)}
                />
                <Form.Input
                  label="Street 1"
                  onChange={(e) => setFieldValue(`street1`, e.target.value)}
                />
                <Form.Input
                  label="Street 2"
                  onChange={(e) => setFieldValue(`street2`, e.target.value)}
                />
              </div>
              <IconButton
                title="Submit"
                text="Submit"
                onClick={async () => {
                  saveCompany(values);
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddCompanyForm;
