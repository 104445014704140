import { Formik } from "formik";
import { Form, Button, Checkbox, Dropdown } from "semantic-ui-react";
import useFetch from "../../../hooks/use-fetch";
import IconButton from "../../ui/button";
import { STATES } from "../../utils/enumerable";

const EditCompany = ({ company, setEditing, getCompanyDetails }) => {
  const api = useFetch();

  const updateCompany = async (values) => {
    await api.fetchData("api/companies/update_company", "PUT", values);
    getCompanyDetails();
    setEditing(false);
  };

  return (
    <Formik
      initialValues={{
        guid: company.guid,
        active: company.active,
        accountNumber: company.accountNumber,
        name: company.companyName,
        twilioPhone: company.twilioPhone,
        transferPhone: company.transferPhone,
        street1: company.streetOne,
        street2: company.steetTwo,
        city: company.city,
        state: company.state,
        zip: company.zip,
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className="edit-company-form">
          <div>
            <div className="space-between-container">
              <Form.Input
                style={{ minWidth: "450px" }} // Could not for the life of me get this to just take remaining space
                label="Company Name"
                value={values.name}
                onChange={(e) => setFieldValue(`name`, e.target.value)}
              />

              <div className="field">
                <label>Active</label>
                <Checkbox
                  checked={values.active}
                  onChange={(e) => {
                    setFieldValue("active", !values.active);
                  }}
                />
              </div>
            </div>
            <div className="space-between-container">
              <Form.Input
                label="Account Number"
                value={values.accountNumber}
                onChange={(e) => setFieldValue(`accountNumber`, e.target.value)}
              />
              <Form.Input
                label="Twilio Number"
                value={values.twilioPhone}
                onChange={(e) => setFieldValue(`twilioPhone`, e.target.value)}
                maxLength="12"
              />
              <Form.Input
                label="Company Transfer Number"
                value={values.transferPhone}
                onChange={(e) => setFieldValue(`transferPhone`, e.target.value)}
                maxLength="10"
              />
            </div>
            <div className="edit-company-address-container">
              <div className="field">
                {/*this just exists to get semantic styling*/}
                <label>State</label>
                <Dropdown
                  fluid
                  search
                  selection
                  value={values.state}
                  options={STATES.map((x) => {
                    return {
                      key: x.text,
                      value: x.value,
                      text: x.text,
                    };
                  })}
                  onChange={(e, data) => {
                    //If using setFieldValue with formik, pass the fieldName too
                    setFieldValue("state", data.value);
                  }}
                />
              </div>
              <Form.Input
                value={values.city}
                label="City"
                onChange={(e) => setFieldValue(`city`, e.target.value)}
              />
              <Form.Input
                value={values.street1}
                label="Street 1"
                onChange={(e) => setFieldValue(`street1`, e.target.value)}
              />
              <Form.Input
                value={values.street2}
                label="Street 2"
                onChange={(e) => setFieldValue(`street2`, e.target.value)}
              />
              <Form.Input
                value={values.zip}
                label="Zip"
                onChange={(e) => setFieldValue(`zip`, e.target.value)}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <IconButton
                color="taupe"
                title="Cancel"
                text="Cancel"
                onClick={async () => {
                  setEditing(false);
                }}
              />
              <IconButton
                title="Submit"
                text="Submit"
                onClick={async () => {
                  updateCompany(values);
                }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditCompany;
