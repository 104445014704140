import React, { useEffect, useState, useContext } from "react";
import { Table, Checkbox, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatTimeSpan } from "../utils/misc";
import { TableContext } from "../../context-provider/TableProvider";
import Paginate from "../ui/pagination";
import useFetch from "../../hooks/use-fetch";

const SubscriptionsTable = ({ api, companyId }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { activePage, recordsPerPage } = useContext(TableContext);

  const fetchSubscriptions = () => {
    api.fetchData("api/subscriptions/company_subscriptions", "POST", {
      companyGuid: companyId,
      recordsPerPage,
      activePage,
    });
  };

  useEffect(() => {
    if (companyId) {
      fetchSubscriptions();
    }
  }, [companyId, activePage]);

  const update = useFetch();

  const updateActiveFlag = async (id) => {
    try {
      setIsUpdating(true);
      await update.fetchData(
        "api/subscriptions/active_subscription",
        "PUT",
        id
      );
      fetchSubscriptions();
      setIsUpdating(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Paginate totalRecords={api.data.total} />
      <Table celled padded>
        <Table.Header>
          <Table.Row className="semantic-header-row">
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Start Time</Table.HeaderCell>
            <Table.HeaderCell>End Time</Table.HeaderCell>
            <Table.HeaderCell>Active</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {!api.isFetching ? (
          <Table.Body>
            {api.data.subscriptions.map((x) => {
              return (
                <Table.Row key={x.guid}>
                  <Table.Cell>
                    <Link className="text-dark" to={`/subscriptions/${x.guid}`}>
                      {x.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{formatTimeSpan(x.startTime)}</Table.Cell>
                  <Table.Cell>{formatTimeSpan(x.endTime)}</Table.Cell>

                  <Table.Cell>
                    <Checkbox
                      checked={x.isActive}
                      disabled={isUpdating}
                      onChange={() => {
                        updateActiveFlag(x.guid);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        ) : (
          <Loader active />
        )}
      </Table>
    </div>
  );
};
export default SubscriptionsTable;
