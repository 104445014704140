import { Dropdown } from "semantic-ui-react";
import { useContext } from "react";
import { UserContext } from "../../context-provider/UserProvider";
import "./style.scss";
const CompanySelect = ({
  setCompanyId,
  fieldName = null,
  defaultCompany = null,
  multiple = false,
}) => {
  const { user, companyId } = useContext(UserContext);
  return (
    <div className={multiple ? "company-dropdown-wide" : "company-dropdown"}>
      <Dropdown
        fluid
        search
        selection
        multiple={multiple}
        defaultValue={defaultCompany ? defaultCompany : companyId}
        options={user.companies.map((x) => {
          return {
            key: x.guid,
            value: x.guid,
            text: x.name,
          };
        })}
        onChange={(e, data) => {
          //If using setFieldValue with formik, pass the fieldName too
          fieldName
            ? setCompanyId(fieldName, data.value)
            : setCompanyId(data.value);
        }}
      />
    </div>
  );
};

export default CompanySelect;
