import { Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

const CustomerCampaignList = ({ campaigns }) => {
  const { customerId } = useParams();
  return (
    <div>
      <h3>Campaign Responses</h3>
      <Segment.Group style={{ width: "700px" }}>
        {campaigns.map((x) => {
          return (
            <Segment className="space-between-container">
              <Link
                className="text-dark"
                to={`/customers/${customerId}/${x.guid}`}
              >
                {x.name}
              </Link>
              <div>{x.dateResponded}</div>
            </Segment>
          );
        })}
      </Segment.Group>
    </div>
  );
};
export default CustomerCampaignList;
