import React, { useEffect, useContext } from "react";
import { Table, Checkbox, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatPhone } from "../utils/misc";
import { TableContext } from "../../context-provider/TableProvider";
import Paginate from "../ui/pagination";

const CompaniesTable = ({ api }) => {
  const { activePage, recordsPerPage } = useContext(TableContext);

  useEffect(() => {
    api.fetchData("api/companies/get_companies", "POST", {
      recordsPerPage,
      activePage,
    });
  }, [activePage]);

  return (
    <div>
      <Paginate totalRecords={api.data.total} />
      <Table celled padded>
        <Table.Header>
          <Table.Row className="semantic-header-row">
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Account Number</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            <Table.HeaderCell>Active</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {!api.isFetching ? (
          <Table.Body>
            {api.data.companies.map((x) => {
              return (
                <Table.Row key={x.guid}>
                  <Table.Cell>
                    <Link className="text-dark" to={`/companies/${x.guid}`}>
                      {x.companyName}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{x.accountNumber}</Table.Cell>
                  <Table.Cell>{formatPhone(x.twilioPhone)}</Table.Cell>
                  <Table.Cell>
                    <Checkbox checked={x.active} disabled />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        ) : (
          <Loader active />
        )}
      </Table>
    </div>
  );
};
export default CompaniesTable;
